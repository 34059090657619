<script setup>
import MemberManage from '@/components/MemberManage.vue';
import { onMounted, ref,unref, watch, toRef} from 'vue'
import SearchMember from '@/components/SearchMember.vue'

const selectedMembers = ref(null);

const memberSelected = (memberData)=>{

    selectedMembers.value = memberData;
}
</script>

<template lang="pug">
div.uk-width-1-1
    ul.uk-breadcrumb 
        li
            span Gestion des paiements 
        li
            span Gestion

div.uk-width-1-1(uk-grid)
    div(class="uk-width-1-1")
        div.uk-card.uk-card-default.uk-card-body
            div(class="uk-width-2-5")
                SearchMember(@searchResults="memberSelected")

MemberManage(:member="selectedMembers" :disable-loading="true")


</template>