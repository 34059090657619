<script setup>
import { onMounted, ref,unref, watch, computed} from 'vue'
import axios from 'axios';
import Calendar from '../components/Calendar.vue';
import {DateTime} from 'luxon';

const selectedReport = ref('');
const reportList = ref([]);
const selectedPos = ref('all');
const posList   = ref([]);
const draw_date = ref(null);

let reportWindow = null;

const showSelectedReport = ()=>{
    
    if(reportWindow){
        reportWindow.close();
    }

    if(selectedReport.value == 'total_sales_salepoints' || selectedReport.value=='draw_collection' || selectedReport.value=='draw_day_collection'){
        let draw_date_iso = DateTime.fromJSDate(draw_date.value).toISO();
        reportWindow = window.open(`/api/v1/reports/generate/${selectedReport.value}/?pos_id=${selectedPos.value}&draw_date=${draw_date_iso}`,"reportViewer","titlebar=no,toolbar=no,menubar=no,location=no,height=700,width=900");
    }
    else if(draw_date.value){
        let draw_date_iso = DateTime.fromJSDate(draw_date.value).toISO();
        reportWindow = window.open(`/api/v1/reports/generate/${selectedReport.value}/?draw_date=${draw_date_iso}`,"reportViewer","titlebar=no,toolbar=no,menubar=no,location=no,height=700,width=900");
    }
    else{
        reportWindow = window.open(`/api/v1/reports/generate/${selectedReport.value}/`,"reportViewer","titlebar=no,toolbar=no,menubar=no,location=no,height=700,width=900");
    }
}

const printSelectedReport = ()=>{

    showSelectedReport();  
    reportWindow.print();                
}

onMounted(async ()=>{
    try {
        const response = await axios.get('/api/v1/reports/types');
        reportList.value = response.data
    } catch (error) {
        console.error(error);
    }
})

const reportListEmpty = computed(()=>{
    return Object.keys(reportList).length?false:true;
})

onMounted(async ()=>{
    try{
        const res = await axios.get(`/api/v1/pointofsales/`);
        posList.value = res.data;
    }
    catch(err){
        console.error(err);
    }

    console.log('selectedReport.value ',selectedReport.value )
})

const enableSubmit = ()=>{

    if(selectedReport.value == ''){
        return false;
    }

    // Point of sale
    if(selectedReport.value=='total_sales_salepoints' || selectedReport.value=='draw_collection' || selectedReport.value=='draw_day_collection'){
        if(selectedPos.value == null) return false;
    }

    // Draw date
    if((selectedReport.value=='next_draw' || selectedReport.value=='total_sales_salepoints' || selectedReport.value=='unpaid_mem' || selectedReport.value=='new_member_since_last_draw' || selectedReport.value=='draw_collection' || selectedReport.value=='draw_day_collection')){
        if(draw_date.value == null) return false;
    }

    return true;
};

watch(selectedReport,(new_value)=>{

    // Clear saved values when type of reports is changed.
    draw_date.value = null;
})
</script>

<template lang="pug">
div.uk-width-1-1
    ul.uk-breadcrumb 
        li
            span Rapports
        li
            span Administratif

div(uk-grid)
    div.uk-width-1-1
        div.uk-card.uk-card-default.uk-card-body
            div.uk-margin
                label.uk-form-label(for="form-name") Sélectionner un rapport à produire
                div.uk-form-controls.uk-width-2-5
                    template(v-if="!reportListEmpty")
                        select#report_selector.uk-select(v-model="selectedReport")            
                            template(v-for="(value,key) in reportList")
                                option(:value="key") {{ value }}
                    template(v-else)
                        div Aucun rapport disponible
                            
                hr
                
                div.uk-margin
                    template(v-if="selectedReport=='total_sales_salepoints' || selectedReport=='draw_collection' || selectedReport=='draw_day_collection'")
                        div.uk-width-2-5
                            label.uk-form-label Point de vente 
                                select.uk-select(v-model="selectedPos")
                                    option(value="all" selected="selected" v-if="selectedReport!='draw_collection' && selectedReport!='draw_day_collection'") Tous
                                    template(v-for="pos in posList")
                                        option(:value="pos._id") {{ pos.pos_name}} 

                    template(v-if="selectedReport=='next_draw' || selectedReport=='total_sales_salepoints' || selectedReport=='unpaid_mem' || selectedReport=='new_member_since_last_draw' || selectedReport=='draw_collection'")
                        div.uk-width-2-5
                            Calendar(type="drawings" v-model="draw_date" label="Date du tirage")
                    template(v-else-if="selectedReport=='draw_day_collection'")
                        div.uk-width-2-5
                            Calendar(type="date_selector" v-model="draw_date" label="Jour")
                                
                div.uk-width-1-1
                    button.uk-button.uk-button-primary.uk-margin-right(@click="showSelectedReport" :disabled="!enableSubmit()") 
                        span.dd-icon(uk-icon="file-text")
                        | Afficher le rapport
                    button.uk-button.uk-button-primary.uk-margin-right(@click="printSelectedReport" :disabled="!enableSubmit()") 
                        span.dd-icon(uk-icon="print")
                        | Imprimer le rapport
</template>

<style scoped>
.dd-icon{
    margin-right:10px;
}
</style>