 <!-- 
    API Endpoints:
        /api/v1/members/?filter=<filter_data>
        /api/v1/members/:member_id
 -->

<script setup>
import UIkit from 'uikit';
import { onMounted, ref,unref, watch, toRef} from 'vue'
import { Field, Form, configure,useField } from 'vee-validate';
import axios from 'axios';
import * as yup from 'yup';
import debounce from "lodash.debounce";

const selectedResultIndex = ref(0);
let refDropdown;
const searchTextbox = ref(null);
const searchResultDropdown = ref(null);
const searchContainer = ref(null);
const searchField = ref("")
const searchTerms = ref("")
const cleanState = ref(true)
const searchResults = ref([]);
const emit = defineEmits(['searchResults']);

const props = defineProps({
    value:{
        type:String,
        default:''
    },
    name: {
        type: String,
        required: false,
    },
    disableClear: {
        type: Boolean,
        required: false,
        default:false
    },
    label:{
        type: String,
        default:"Rechercher un membre"
    }
});



const name = toRef(props, 'name');

const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
    meta,
    setErrors,
    errors,
    validate
} = useField(name, undefined, {
    initialValue: props.value,
});



watch(inputValue,async (value,previous_value)=>{
    
    console.log('inputValue Changed: ',value)
    console.log('compare:',value,inputValue.value)
   
    if(value == ''){
        console.log('serarchterm ',value)
        searchTerms.value = '';
        searchTextbox.value.value = "";
        cleanState.value = true;
        emit('searchResults',null);
    }
    else{
        console.log(`Loading user ${value}`);
        console.log('searchTextbox.value.value',searchTextbox.value.value)

        await validate();
        
        if(value != inputValue.value || searchTextbox.value.value=="" || previous_value != ""){
            getMember(value);
        }
    }
});




onMounted(()=>{
    console.log('calling func prop');
    
    console.log('calling func prop end');
    const options = {
        target:searchContainer.value,
        toggle:"",
        mode:"click",
        // stretch:true,
        stretch:'x',
        "boundary-x":searchContainer.value,
        // shift:false,
        // flip:false,
        pos:'bottom-left'
    }
    refDropdown = UIkit.drop(searchResultDropdown.value,options);
})

const searchMembers = async (searchTerms)=>{
   
    console.log('searchMembers',searchTerms);
    
    searchResults.value = await fetchMembers(searchTerms);
    console.log('searchMembers result',searchResults.value);
    
    refDropdown.show();
}


const searchMembers2 = async (searchTerms)=>{
   
   console.log('searchMembers',searchTerms);
   
   searchResults.value = await fetchMembers(searchTerms);
   console.log('searchMembers result',searchResults.value);
   
   if(searchResults.value && searchResults.value[0]){

       getMember(searchResults.value[0]._id);
   }
}


const fetchMembers = async (searchTerms)=>{
    
    console.log('fetchMembers',searchTerms);

    try{
        const res = await axios.get(`/api/v1/members/`,{params:{filter:searchTerms}});
        console.log('resdata',res.data)
        return res.data;
    }
    catch(err){
        console.error(err);
    }
}




const getMember = async (member_id)=>{
    
    console.log('getMember',member_id)
    
    try{
        const res = await axios.get(`/api/v1/members/${member_id}`);
        let val = `${res.data.number} / ${res.data.firstname} ${res.data.lastname} `;
        // searchTextbox.value.value = `${res.data.number} - ${res.data.firstname} ${res.data.lastname} - ${formatTelephone(res.data.telephone1)} ${formatTelephone(res.data.telephone2)}`;

        if(res.data.telephone1){
            val += `/ ${formatTelephone(res.data.telephone1)}`;
        }
        if(res.data.telephone2){
            val += `/ ${formatTelephone(res.data.telephone2)}`;
        }
        searchTextbox.value.value = val;

        setTimeout(()=>{ refDropdown.hide(0); },0);
        inputValue.value = res.data._id;
        emit('searchResults',res.data);
    }
    catch(err){
        console.error(err);
    }
}


const keyupDown = ()=>{
    
    console.log('keyupDown')
    
    if(selectedResultIndex.value < searchResults.value.length-1){
        selectedResultIndex.value++;
    }
    console.log('-----> ',selectedResultIndex.value)
    console.log('-------->',searchResults.value)

    if(selectedResultIndex.value > searchResults.value.length){
        selectedResultIndex.value = 0;
        console.log('reset index value')
    }
}

const keyupUp = ()=>{
    
    console.log('keyupUp')
    
    if(selectedResultIndex.value > 0){
        selectedResultIndex.value--;
    }
    console.log('-----> ',selectedResultIndex.value)
    console.log('-------->',searchResults.value)

    if(selectedResultIndex.value > searchResults.value.length){
        selectedResultIndex.value = searchResults.value.length>0?searchResults.value.length-1:0;
        console.log('reset index value')
    }
}

const keyEnter = async ()=>{
   
    if(!ignoreInput){

        getMember(searchResults.value[selectedResultIndex.value]._id);
    }

}

let ignoreInput = false;

let daa


const textInput22 = debounce(function(){

    console.log('ignoreInput',ignoreInput)
    if(!ignoreInput){
        searchMembers(searchTextbox.value.value); 
    }
},300)


const textInput222 = function(){
   
    console.log('ignoreInput',ignoreInput)
    if(!ignoreInput){
        searchMembers(searchTextbox.value.value); 
        console.log('haha')
    }
        console.log('haha2')
}

const textInput = function(event_input){
    console.log('TextInput')
    if(event_input.data == '<'){
        ignoreInput = true
    }
    else if(event_input.data == '>'){
        ignoreInput = false;
    }
    textInput22();
}


var buff = "";
var lastTime = 0;

let scannerInputActive = false;

window.onkeypress = function(event){
    
    if(event.key=='>'){
        scannerInputActive = true;
        searchMembers2(buff);
    }

    if(scannerInputActive){
        buff += event.key;
    }
    if(event.key == '<'){
        scannerInputActive = true;
        buff = "";
    }
};

const clearInput = ()=>{
    console.log('clearInput called');
    if(!props.disableClear){
        searchTextbox.value.value = "";
        inputValue.value = "";
        console.log('INSINDFE')
    }
}


defineExpose({clearInput})

// TODO this should be in utilities module
const formatTelephone = (telephone)=>{
    
    let ret = telephone;
    
    const areaCode = telephone.substring(0,3);
	const middle = telephone.substring(3,6);
	const last = telephone.substring(6,10);
    
	if(telephone.length > 6){
        ret = `(${areaCode}) ${middle}-${last}`;
    }
	else if(telephone.length > 3){
        ret = `(${areaCode}) ${middle}`; 
    }
	else if(telephone.length > 0){
        ret = `(${areaCode}`;
    }
    return ret;
}
</script>

<template lang="pug">
div.uk-width-1-1(ref="searchContainer")
    div.uk-width-1-1
        label.uk-form-label {{ props.label }}
        //- label.uk-form-label Rechercher un membre
        div.uk-form-controls
            div.uk-inline.uk-width-1-1
                a.uk-form-icon.uk-form-icon-flip(uk-icon="icon: close" v-if="inputValue!=''" @click="clearInput")
                input#memberSearchBox.uk-input( 
                    type='text' 
                    ref="searchTextbox"  
                    :class="{'uk-form-danger': errorMessage}" 
                    :readonly="inputValue!=''" 
                    @input="textInput" 
                    @keyup.up="keyupUp" 
                    @keyup.down="keyupDown" 
                    @keyup.enter="keyEnter"                    
                    
                )
                div.dd_field_error {{ errorMessage }}
    //- div.uk-margin(ref="searchResultDropdown" uk-drop)
    div.uk-margin(ref="searchResultDropdown")
        div#memberSelector.uk-card.uk-card-body.uk-card-secondary
            template(v-if="searchResults && searchResults.length")
                ul.uk-list.uk-list-divider
                    template(v-for="(member,index) in searchResults")
                        a(@click.prevent="getMember(member._id)")
                            li(:class="{selectedMember:index==selectedResultIndex, memberRow:true}") 
                                span.dd-member-number {{ member.number }} &nbsp;
                                span.dd-member-firstname {{ member.firstname }} &nbsp;
                                span.dd-member-lastname {{ member.lastname }} &nbsp;
                                div.dd-member-telephone
                                    span.dd-member-telephone1 {{ formatTelephone( member.telephone1) }}
                                    template(v-if="member.telephone2")
                                        span.dd-member-telephone2 &nbsp; {{ formatTelephone(member.telephone2) }}
            template(v-else-if="cleanState")
                div Recherche par numéro de membre, numéro de téléphone, nom ou nom de famille
            template(v-else)
                div Aucun Membre Trouvé
</template>


<style scoped>
.dd-member-number{
    width: 70px;
    display: inline-block;
    text-align: right;
    font-weight:bold;
}
.dd-member-telephone1, .dd-member-telephone2{
    font-weight:bold;
    font-style:italic;
}
.dd-member-telephone{
    position:absolute;
    right:0;
    top:2px;
}
input:read-only {
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
}
#memberSelector{
    overflow-y:auto;
    min-height:200px;
    max-height:300px;
}
.selectedMember{
    background: #414141;
}

.memberRow{
    padding:3px 15px;
    position:relative;
    /* border-top: 1px solid black; */
    /* border-bottom: 1px solid rgb(196, 196, 196); */
    color:rgb(194, 194, 194)
}

.memberRow:hover{
    text-decoration: none;
    background: #414141;
}
</style>