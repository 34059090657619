<script setup>
import { onMounted, ref,unref, watch, computed} from 'vue'
import axios from 'axios';
import { DateTime } from 'luxon';
import {useUsersStore} from '@/stores/user.js'
import Calendar from '../components/Calendar.vue';
import UIkit from 'uikit';

const userStore = useUsersStore();
const selectedReport = ref(null);
const draw_date = ref(null);
const pointOfSales = ref([]);
const selectedPos = ref(null);
const reportData = ref([]);
const reportUrl = ref('');

const reportList = ref(
    {
        'draw_collection':'Vente de la semaine',
        'draw_day_collection':'Vente de la journée',
    });

const showSelectedReport = ()=>{ 

    

    // let reportUrl.value = "";
    switch(selectedReport.value){
        case 'draw_collection':
            reportUrl.value=`/api/v1/reports/pos/generate/${selectedReport.value}/?pos_id=${selectedPos.value}&draw_date=${DateTime.fromJSDate(draw_date.value).toISO()}`;
            break;
        case 'draw_day_collection':
            reportUrl.value=`/api/v1/reports/pos/generate/${selectedReport.value}/?pos_id=${selectedPos.value}&day=${DateTime.fromJSDate(draw_date.value).toISO()}`;
            break;

    }     
    UIkit.modal('#reportModal').show();  
    // window.open(url,"reportViewer","height=700,width=900");
}

const printSelectedReport = async ()=>{
    
    let url;
    
    try{
        switch(selectedReport.value){
            case 'draw_collection':
                url=`/api/v1/reports/pos/data/${selectedReport.value}/?pos_id=${selectedPos.value}&draw_date=${DateTime.fromJSDate(draw_date.value).toISO()}`;
                break;
            case 'draw_day_collection':
                url=`/api/v1/reports/pos/data/${selectedReport.value}/?pos_id=${selectedPos.value}&day=${DateTime.fromJSDate(draw_date.value).toISO()}`;
                break;

        }   
        const res = await axios.get(url);
        reportData.value = res.data;
        
        print(DateTime.fromHTTP(res.headers.date).toFormat('yyyy-LL-dd HH:mm'),reportData.value);
    }
    catch(err){
        console.log('Error printing')
        console.error(err);
    }
}

watch(selectedReport,(new_val)=>{
console.log('selectedReport')
    draw_date.value = null;
})

const reportListEmpty = computed(()=>{
    return Object.keys(reportList).length?false:true;
});


const enableSubmit = computed(()=>{
    if(selectedReport.value=='draw_collection' || selectedReport.value=='draw_day_collection' ){
        return draw_date.value !== null;
    }
    else if(selectedReport.value !== null){
        return true;
    }
    return false;
});


onMounted(async ()=>{

    // TODO create a component for the point of sales selector
    if(userStore.isPosUser()){
        selectedPos.value = userStore.currentUser.allowed_pos
    }
    else{
        try{
            const response = await axios.get(`/api/v1/pointofsales`);        
            pointOfSales.value = response.data;
        }
        catch(err){
            console.error(err);
        }
    }
});

const print = (generated,data)=>{
    
    var appscheme = 'tmprintassistant://';           //scheme name                                                                           Input condition:Required
    var host = 'tmprintassistant.epson.com/';        //host name                                                                             Input condition:Required
    var action       = 'print?';                     //action name                                                                           Input condition:Required
    var query_success = 'success';                   //URL to be displayed when TM Print Assistant has terminated normally.                  Input condition:Can be omitted
    var query_ver = 'ver';                           //Version number                                                                        Input condition:Required
    var query_datatype = 'data-type';                //Type of print data                                                                    Input condition:Required
    var query_data = 'data';                         //Print data                                                                            Input condition:Required
    var query_reselect = 'reselect';                 //Reconfiguration of printer upon communication error                                   Input condition:Can be omitted (Default : no)
    var query_cut = 'cut';                           //Cutter setting                                                                        Input condition:Can be omitted (Default : no)
    var query_fittowidth = 'fit-to-width';           //Scaling up or down print data in accordance with paper width Supported data-type: pdf Input condition:Can be omitted (Default : no)
    var query_paperwidth = 'paper-width';            //Specifying paper width (mm)                                                           Input condition:Can be omitted (Default : 80)
    var pdfData = '';
    var pdfPath = './receipt.pdf';                   //Path of pdf file
    var xmlData ='<epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">\n' +
        '<text align="center"/>\n' +
        '<logo key1="32" key2="32" align="center"/>' +
        '<text dh="true" dw="false" em="true" align="center">Chevaliers de Colomb&#10;</text>\n' +
        '<text dh="false" dw="false" em="false">  Saint-François-de-Madawaska   &#10;</text>\n' +
        '<feed line="1"/>\n' +
        '<text align="left"/>\n';
    xmlData += `<text dh="true" em="true" align="left">${data.pos_name}&#10;</text>\n`;

    if(data.drawDate){
        xmlData += '<text dh="false" dw="false" em="true">Ventes de la semaine&#10;</text>\n';
    }
    else if(data.day){
        xmlData += '<text dh="false" dw="false" em="true">Ventes de la journée&#10;</text>\n';
    }
    
    xmlData += '<text dh="false" dw="false" em="false">Généré le '+generated+'&#10;</text>\n';

    if(data.drawDate){
        xmlData += '<text>Pour le tirage du '+data.drawDate+'&#10;</text>\n';
    }
    else if(data.day){
        xmlData += '<text>Pour la journée du '+data.day+'&#10;</text>\n';
    }
    xmlData += '<feed line="1"/>\n';
  
    xmlData += '<text align="center">------------------------------------------&#10;</text>\n';
    
    if(data.count > 1){
        xmlData += `<text dh="true" em="true" align="left">${data.count} transactions &#10;</text>\n`;
    }
    else{
        xmlData += `<text dh="true" em="true" align="left">${data.count} transaction &#10;</text>\n`;
    }
    
    xmlData += `<text dh="true" em="true" align="left">Total: ${data.amountTotal}$ &#10;</text>\n`;
    xmlData += '<text dh="false" em="false" align="center">------------------Détails-----------------&#10;</text>\n';
    
    for(let key in data.data){
        xmlData += `<text align="left">Méthode Paiement:</text>\n`;
        xmlData += `<text align="left">${data.data[key]._id} &#10;</text>\n`;
        
        if(data.count > 1){
            xmlData += `<text align="left">${data.data[key].count} transactions&#10;</text>\n`;
        }
        else{
            xmlData += `<text align="left">${data.data[key].count} transaction&#10;</text>\n`;
        }
        
        xmlData += `<text align="left">Total: ${data.data[key].total}$ &#10;</text>\n`;
        xmlData += '<text align="center">-------&#10;</text>\n';
    }
    xmlData += '<feed line="5"/>\n';
    xmlData += '<cut type="feed"/>\n';
    xmlData += '</epos-print>'; //ePOS-Print XML data
    
    function sendXml() {
        var success = window.location.href;
        var ver = '1';
        var datatype = 'eposprintxml';
        var htmlData = '';
        var reselect = 'yes';
        var urlData = '';

        urlData = appscheme + host + action + 
            query_success + '=' + encodeURIComponent(success) + '&' + 
            query_ver + '=' + ver + '&' + 
            query_datatype + '=' + datatype + '&' + 
            query_reselect + '=' + reselect + '&' + 
            query_data + '=' + encodeURIComponent(xmlData);
        location.href = urlData;
    }
    console.log('xmlData',xmlData)
    sendXml()
}


</script>

<template lang="pug">
div.uk-width-1-1
    ul.uk-breadcrumb 
        li
            span Rapports 
        li
            span Point de vente

div(uk-grid)
    div.uk-width-1-1
        div.uk-card.uk-card-default.uk-card-body
            div.uk-margin
                label.uk-form-label(for="form-name") Sélectionner un rapport à produire
                div.uk-form-controls.uk-width-2-5
                    template(v-if="!reportListEmpty")
                        select#pos_report_selector.uk-select(v-model="selectedReport")            
                            template(v-for="(value,key) in reportList")
                                option(:value="key") {{ value }}
                    template(v-else)
                        div Aucun rapport disponible    
                hr
                div.uk-margin
                    template(v-if="!userStore.isPosUser()")
                        div.uk-margin.uk-width-2-5
                            label.uk-form-label Point de vente
                            select#pos_report_pos.uk-select(v-model="selectedPos")
                                template(v-for="pos in pointOfSales")
                                    option(:value="pos._id") {{ pos.pos_name}}   
                    
                    template(v-if="selectedReport=='draw_collection'")
                        div.uk-width-2-5
                            Calendar(type="drawings" v-model="draw_date" label="Date du tirage")
                    template(v-else)
                        div.uk-width-2-5
                            Calendar(type="date_selector" v-model="draw_date" label="Jour")
                div.uk-width-1-1
                    button.uk-button.uk-button-primary.uk-margin-right(@click="showSelectedReport" :disabled="!enableSubmit") 
                        span.dd-icon(uk-icon="file-text")
                        | Afficher le rapport
                    button.uk-button.uk-button-primary.uk-margin-right(@click="printSelectedReport" :disabled="!enableSubmit") 
                        span.dd-icon(uk-icon="print")
                        | Imprimer le rapport

div#reportModal.uk-flex-top.uk-modal-container(uk-modal="bg-close:false;esc-close:false;" )
    div.uk-modal-dialog.uk-margin-auto-vertical
        button.uk-modal-close-default(type="button" uk-close)
        div.uk-modal-body(uk-overflow-auto)
            iframe(:src="reportUrl" style="height:200px;width:100%;border:none;overflow:hidden;" onload='javascript:(function(o){o.style.height=o.contentWindow.document.body.scrollHeight+"px";}(this));')
        div.uk-modal-footer.uk-text-right
            button.uk-button.uk-button-primary.uk-modal-close(type="button") Fermer
</template>

<style scoped>
.dd-icon{
    margin-right:10px;
}
</style>