<script setup>
import {DateTime} from 'luxon';
import {ref, watch} from 'vue'
import UIkit from 'uikit';
import axios from 'axios';

const props = defineProps({
    transactions: {
        type: Object,
    },
    selectedMember:{
        type: Object
    },
    loading:{
        type:Boolean
    }
});
const loading = ref(false)
watch(()=>props.loading,(value)=>{
    loading.value = value;
})

const emit = defineEmits(['reloadData'])

const cancelTransaction = async (transaction_id)=>{
    
    try{
        await UIkit.modal.confirm('Voulez-vous vraiment anuller cette transaction?');
        
        try{
            loading.value = true;
            const res = await axios.delete(`/api/v1/transactions/${transaction_id}`);
            
            // Force data reload
            emit('reloadData');
            loading.value = false;
        }
        catch(err){
            console.error(err);
        }
    }
    catch(err){}
    
}

// TODO this should be a common function
const payment_method = (method)=>{
    switch(method){
        case 'cash':
            return 'Argent Comptant';
        case 'interact':
            return 'Interac';
        case 'accesd':
            return 'Virement Bancaire';
        case 'check':
            return 'Chèque';
    }
}
</script>

<template lang="pug">
div(class="uk-width-1-1 uk-width-1-1")
    div.uk-card.uk-card-default.uk-card-body(:class="{'dd-disabled-card': props.selectedMember==null}")
        div.uk-overlay-primary.uk-position-cover(v-if="loading" style="z-index:1000;")
            div.uk-position-center
                span(uk-spinner="ratio:2")
        h4.uk-heading-line.uk-text-center 
            span Historique des transactions
        table.uk-table.uk-table-small.uk-table-divider.uk-table-hover.uk-table-middle.uk-table-responsive
            tbody
                template(v-if="!(props.transactions && props.transactions.length)")
                    tr
                        td Aucune transaction trouvée
                template(v-else)
                    template(v-for="transaction in props.transactions")
                        tr(:class="{'dd-cancelled':transaction.cancelled}")
                            td
                                span.uk-link-text(href="#")
                                    b {{ DateTime.fromISO(transaction.date).toFormat('yyyy-LL-dd HH:mm') }}
                                    br
                                    template(v-if="transaction.pos_id != null")
                                        | {{ transaction.pos.pos_name }}
                                        br
                                        i {{ transaction.user.display_name }}
                                    template(v-else)
                                        | {{ transaction.user.display_name }}
                                    template(v-if="transaction.imported")
                                        br
                                        span.uk-label(style="font-size:0.8em;") Transaction Importée
                                    template(v-if="transaction.cancelled")
                                        br
                                        span.uk-label.uk-label-warning(style="font-size:0.8em;")
                                            i Transaction Annulée
                            td
                                span
                                    b   
                                        i Pour les tirages
                                br
                                span 
                                    b Du: 
                                    template(v-if="transaction.amount != 0 && !transaction.cancelled")
                                        | {{ DateTime.fromISO(transaction.current_endDate).toFormat('yyyy-LL-dd') }}
                                    template(v-else)
                                        | -
                                br
                                span 
                                    b Au: 
                                    template(v-if="transaction.amount != 0 && !transaction.cancelled")
                                        | {{ DateTime.fromISO(transaction.new_endDate).toFormat('yyyy-LL-dd') }}
                                    template(v-else)
                                        | -
                            td
                                template(v-if="!(transaction.imported || transaction.cancelled)")
                                    button.uk-button.uk-button-danger.uk-button-small(@click="cancelTransaction(transaction._id)") Annuler la transaction
                            td.uk-text-right
                                h2 {{ transaction.amount }}$
                                span.uk-text-meta # {{ transaction.invoiceNumber }}
                                br
                                span.uk-label(style="font-size:0.6em; background-color:#8f8f8f;") {{ payment_method(transaction.payment_method) }}
                       
                        template(v-if="transaction.history && transaction.history.length")
                            tr.dd-sub-row
                                td.uk-text-left(colspan="4")
                                    div()
                                        i Nombre de modifications: {{ transaction.history.length }}
                        //- template(v-if="transaction.cancelled")
                        //-     tr.dd-sub-row
                        //-         td(colspan="4")
                        //-             span.uk-label.uk-label-warning    
                        //-                 i Transaction Annulée

                        //- tr
                        //-     td(colspan=2)
                        //-         template(v-for="changes in transaction.history")
                        //-             template(v-for="change in changes")
                        //-                 | {{ change.key }} {{ change.from }} {{ change.to }}
                        //-                 br


</template>

<style scoped>
.dd-disabled-card{
    opacity:0.5;
}
.dd-cancelled{
    opacity:0.5;
}
.dd-sub-row{
    border-top:none !important;
}
</style>

