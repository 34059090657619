<script setup>
import { onMounted, ref,unref, watch, toRef} from 'vue'
import { Field, Form } from 'vee-validate';
import * as yup from 'yup';
import axios from 'axios';
import SearchMember from '@/components/SearchMember.vue'
import Calendar from '@/components/Calendar.vue'
import UIkit from 'uikit';
import { schema_add_winner } from '@/../../validation_schemas/winners.mjs';

const defaultValues = {
    "member_id":"",
    "draw_date":"",
    "amount_won":"",
    "amount_week":"",
    "member_paid":""
}

const loading = ref(true);

// const validationSchema = yup.object({
//     "member_id":yup.string().required(),
//     "draw_date":yup.string().matches(/[0-9\-\s]+/).required(),
//     "amount_won":yup.number().required(),
//     "amount_week":yup.number().integer().required(),
//     "member_paid":yup.boolean().required(),
//     "draw_by":yup.string().required(),
//     "pos_id":yup.string().required(),
// })

const onSubmit = async (values,{resetForm})=>{
    loading.value = true;
    try{
        const res = await axios.post(`/api/v1/winners/`,values);
        resetForm();
        UIkit.notification("<span uk-icon='icon: check'></span> Gagnant ajouté avec succès",{status:'success',pos:'bottom-right'});
    }
    catch(err){
        console.error(err);
        UIkit.notification("<span uk-icon='icon: close'></span> Erreure lors de l'ajout du gagnant",{status:'danger',pos:'bottom-right'});
    }
    loading.value = false;
}

const posList = ref([]);
onMounted(async ()=>{
    loading.value = true;
    try{
        const res = await axios.get(`/api/v1/pointofsales/`);
        posList.value = res.data;

        console.log('posList',posList.value)
    }
    catch(err){
        console.error(err);
    }
    loading.value = false;
})
</script>

<template lang="pug">
div.uk-width-1-1
    ul.uk-breadcrumb 
        li
            span Gagnants Archivés 
        li
            span Ajouter

div(uk-grid)
    div.uk-width-1-1
        div.uk-card.uk-card-default.uk-card-body
            Form(@submit="onSubmit" :validation-schema="schema_add_winner" v-slot="{ resetForm, meta, validate}" :initial-values="defaultValues")
            
                div.uk-width-2-5.uk-margin
                    SearchMember(name="member_id" v-bind="field")

                div.uk-width-2-5.uk-margin
                    Calendar(name="draw_date" type="winners" label="Date du tirage")
                
                Field(name="amount_won" v-slot="{ field, errorMessage }")
                    div.uk-margin.uk-width-1-5
                        label.uk-form-label(for="form-amount-won") Montant gagné
                        div.uk-inline
                            span.uk-form-icon.uk-form-icon-flip $
                            input.uk-input#form-amount-won(type="text" v-bind="field")

                Field(name="amount_week" v-slot="{ field, errorMessage }")
                    div.uk-margin.uk-width-1-5
                        label.uk-form-label(for="form-amount-week") Montant de la semaine
                        div.uk-inline
                            span.uk-form-icon.uk-form-icon-flip  $
                            input.uk-input#form-amount-week(type="text" v-bind="field")

                Field(name="member_paid" v-slot="{ field, errorMessage }")
                    div.uk-margin.uk-width-1-6
                        label.uk-form-label(for="form-member-paid") Payé?
                        div.uk-form-controls
                            select.uk-select(v-bind="field")
                                option(value="true") Oui
                                option(value="false") Non

                Field(name="draw_by" v-slot="{ field, errorMessage }")
                    div.uk-margin
                        label.uk-form-label(for="form-amount-won") Pigé par
                        div.uk-form-controls.uk-width-2-5
                            input.uk-input#form-amount-won(type="text" v-bind="field")
                
                Field(name="pos_id" v-slot="{ field, errorMessage }")
                    div.uk-margin.uk-width-2-5
                        label.uk-form-label(for="form-member-paid") Point de vente
                        div.uk-form-controls
                            select.uk-select(v-bind="field")
                                option(value="none") N/D
                                template(v-for="pos in posList")
                                    option(:value="pos._id") {{pos.pos_name}}

                div.uk-width-1-1
                    button.uk-button.uk-button-primary.uk-margin-right(type="submit" :disabled="!meta.valid || loading") Enregistrer
                    button.uk-button.uk-button-link(@click="resetForm" :disabled="!meta.dirty || loading") Annuler
</template>