import { createRouter, createWebHashHistory } from 'vue-router'
import InfoMemberView from '../views/InfoMemberView.vue'
import AddDepositView from '../views/AddDepositView.vue'
import ModifyDepositView from '../views/ModifyDepositView.vue'
import AddMemberView from '../views/AddMemberView.vue'
import ModifyMemberView from '../views/ModifyMemberView.vue'
import ViewMemberPaymentMgmt_Dashboard from '../views/ViewMemberPaymentMgmt_Dashboard.vue'
import ViewMemberPaymentMgmt_Manage from '../views/ViewMemberPaymentMgmt_Manage.vue'
import ViewMemberPaymentMgmt_Group_Add from '../views/ViewMemberPaymentMgmt_Group_Add.vue'
import ViewMemberPaymentMgmt_Group_List from '../views/ViewMemberPaymentMgmt_Group_List.vue'
import ReportsView from '../views/ReportsView.vue'
import PosReportsView from '../views/PosReportsView.vue'
// import WinnersArchiveView from '../views/WinnersArchiveView.vue'
import ViewWinnersArchive_Add from '../views/ViewWinnersArchive_Add.vue'
import ViewWinnersArchive_List from '../views/ViewWinnersArchive_List.vue'
// import SettingsView from '../views/SettingsView.vue'
import ViewSettings_General from '../views/ViewSettings_General.vue'
import ViewSettings_Messages_Receipt from '../views/ViewSettings_Messages_Receipt.vue'
import ViewSettings_Messages_Homepage from '../views/ViewSettings_Messages_Homepage.vue'
import ViewSettings_Messages_Adminpage from '../views/ViewSettings_Messages_Adminpage.vue'
import ViewSettings_PointOfSale_Add from '../views/ViewSettings_PointOfSale_Add.vue'
import ViewSettings_PointOfSale_List from '../views/ViewSettings_PointOfSale_List.vue'
import ViewSettings_Users_Add from '../views/ViewSettings_Users_Add.vue'
import ViewSettings_Users_List from '../views/ViewSettings_Users_List.vue'
import HelpView from '../views/HelpView.vue'
import UIkit from 'uikit'

const router = createRouter({
    // history: createWebHistory(import.meta.env.BASE_URL),
    history:createWebHashHistory(),
    routes: [
        {
            path: '/',
            redirect: '/info-member'
        },       
        {
            path: '/add-deposit',
            name: 'deposit-add',
            component: AddDepositView
        },
        {
            path: '/modify-deposit',
            name: 'deposit-modify',
            component: ModifyDepositView
        },
        {
            path: '/add-member',
            name: 'member-add',
            component: AddMemberView
        },
        {
            path: '/modify-member',
            name: 'member-modify',
            component: ModifyMemberView
        },
        {
            path: '/info-member',
            name: 'member-info',
            component: InfoMemberView
        },
        {
            path: '/member-paymgmt-dashboard',
            name: 'member-paymgmt-dashboard',
            component: ViewMemberPaymentMgmt_Dashboard
        },
        {
            path: '/member-paymgmt-manage',
            name: 'member-paymgmt-manage',
            component: ViewMemberPaymentMgmt_Manage
        },
        {
            path: '/member-paymgmt-groups-add',
            name: 'member-paymgmt-groups-add',
            component: ViewMemberPaymentMgmt_Group_Add
        },
        {
            path: '/member-paymgmt-groups-list',
            name: 'member-paymgmt-groups-list',
            component: ViewMemberPaymentMgmt_Group_List
        },
        {
            path: '/reports-administrative',
            name: 'reports-administrative',
            component: ReportsView
        },
        {
            path: '/reports-pos',
            name: 'reports-pos',
            component: PosReportsView
        },
        {
            path: '/winners-archive/add',
            name: 'winners-archive-add',
            component: ViewWinnersArchive_Add
        },
        {
            path: '/winners-archive/list',
            name: 'winners-archive-list',
            component: ViewWinnersArchive_List
        },
        {
            path: '/settings/general',
            name: 'settings-general',
            component: ViewSettings_General
        },
        {
            path: '/settings/messages/receipt',
            name: 'settings-messages-receipt',
            component: ViewSettings_Messages_Receipt
        },
        {
            path: '/settings/messages/homepage',
            name: 'settings-messages-homepage',
            component: ViewSettings_Messages_Homepage
        },
        {
            path: '/settings/messages/adminpage',
            name: 'settings-messages-adminpage',
            component: ViewSettings_Messages_Adminpage
        },
        {
            path: '/settings/pointofsale/add',
            name: 'settings-pointofsale-add',
            component: ViewSettings_PointOfSale_Add
        },
        {
            path: '/settings/pointofsale/list',
            name: 'settings-pointofsale-list',
            component: ViewSettings_PointOfSale_List
        },
        {
            path: '/settings/users/add',
            name: 'settings-users-add',
            component: ViewSettings_Users_Add
        },
        {
            path: '/settings/users/list',
            name: 'settings-users-list',
            component: ViewSettings_Users_List
        },
        {
            path: '/help',
            name: 'help',
            component: HelpView
        }
    ]
})

router.afterEach(()=>{
    UIkit.offcanvas('#offcanvas-menu').hide();
})

export default router