import * as yup from 'yup';


const message_required  = "Ce champ est requis"
const message_number    = "Doit être un numéro plus grand que 0"
const message_telephone = "Le numéro doit être sous la forme suivante: 555 555-5555";
const message_email     = "Doit être un courriel valide";
const message_amount    = "Le montant doit être au miniumum 2$";
const message_member    = 'Vous devez sélectionner un membre.';

const field_amount = yup.number()
    .typeError(message_number)
    .required(message_required)
    .nullable()
    .min(2,message_amount)
    .test('is-multiple-of-2','Le montant doit être un multiple de 2$',(value,context)=>(value%2)==0)

export const schema_add_transaction_as_user = yup.object({
    "member_id":yup.string().matches(/[a-f0-9]{24}/,message_member).required(message_member),
    "automatic_invoice_number":yup.boolean(),
    "amount":field_amount,
    "pos_id":yup.string().required(),
    "date":yup.date().required(),
    "payment_method":yup.string().required(),
    "invoice_number":yup.string().when('automatic_invoice_number',{
        is:true,
        then: (schema) => schema.optional(),
        otherwise: (schema)=>schema.required()
    }),
});


export const schema_edit_transaction_as_user = yup.object({
    "transaction_id":yup.string().required(),
    "amount":field_amount,
    "pos_id":yup.string().required(),
    "date":yup.date().required(),
    "payment_method":yup.string().required(),
});

export const schema_edit_transaction_as_pointofsale = yup.object({
    "transaction_id":yup.string().required(),
    "amount":field_amount
});


export const schema_add_transaction_as_pointofsale = yup.object({
    "member_id":yup.string().matches(/[a-f0-9]{24}/,message_member).required(message_member),
    "amount":field_amount,
});


export const schema_add_member = yup.object({
    "active":yup.boolean().nullable(),
    "number":yup.string().when('automatic_number',{
        is:true,
        then: (schema) => schema.optional(),
        otherwise: (schema)=>schema.matches(/^[0-9]+$/,message_number).required(message_required)
    }),
    "automatic_number":yup.boolean(),
    "firstname":yup.string().when('active',{
        is:true,
        then:(schema) => schema.required(message_required),
        otherwise:(schema)=>schema.optional(),
    }),
    "lastname":yup.string().when('active',{
        is:true,
        then:(schema) => schema.required(message_required),
        otherwise:(schema)=>schema.optional(),
    }),
    "telephone1":yup.string().when('active',{
        is:true,
        then:(schema) => schema.matches(/^[0-9\-\s]+$/,message_telephone).required(message_required),
        otherwise:(schema)=>schema.optional(),
    }),
    "telephone2":yup.string().notRequired().matches(/^[0-9\-\s]+$/,{ excludeEmptyString: true ,message:message_telephone}),
    "address":yup.string().notRequired(),
    "city":yup.string().when('active',{
        is:true,
        then:(schema) => schema.required(message_required),
        otherwise:(schema)=>schema.optional(),
    }),
    "email":yup.string().email(message_email).notRequired()
});

export const schema_edit_member = yup.object({
    "active":yup.boolean().nullable(),
    // "number":yup.string().when('automatic_number',{
    //     is:true,
    //     then: (schema) => schema.optional(),
    //     otherwise: (schema)=>schema.matches(/^[0-9]+$/,message_number).required(message_required)
    // }),
    // "automatic_number":yup.boolean(),
    "firstname":yup.string().when('active',{
        is:true,
        then:(schema) => schema.required(message_required),
        otherwise:(schema)=>schema.optional(),
    }),
    "lastname":yup.string().when('active',{
        is:true,
        then:(schema) => schema.required(message_required),
        otherwise:(schema)=>schema.optional(),
    }),
    "telephone1":yup.string().when('active',{
        is:true,
        then:(schema) => schema.matches(/^[0-9\-\s]+$/,message_telephone).required(message_required),
        otherwise:(schema)=>schema.optional(),
    }),
    "telephone2":yup.string().notRequired().matches(/^[0-9\-\s]+$/,{ excludeEmptyString: true ,message:message_telephone}),
    "address":yup.string().notRequired(),
    "city":yup.string().when('active',{
        is:true,
        then:(schema) => schema.required(message_required),
        otherwise:(schema)=>schema.optional(),
    }),
    "email":yup.string().email(message_email).notRequired()
});