import * as yup from 'yup';

const field_pos_name = yup.string()
    .required()
    .min(2,(min)=>`Le nom du point de vente doit contenir au minimum ${min} charactères`)
    .max(35,(max)=>`Le nom du point de vente doit contenir au maximum ${max} charactères`)
    .trim()
    .matches(/^[\p{Letter}\s]+$/ui,"Le nom du point de vente doit contenir des charactères alpha-numérique seulement.");

const field_pos_id = yup.string()
    .required()
    .length(24)
    .trim()
    .matches(/[0-9A-Fa-f]/)
    .strip();

export const schema_add_pointofsale = yup.object({
    "pos_name":field_pos_name
});

export const schema_edit_pointofsale = yup.object({
    "pos_name":field_pos_name,
    "pos_id":field_pos_id
});