<script setup>
import { onMounted, ref,unref, watch} from 'vue'
import { Field, Form, ErrorMessage } from 'vee-validate';
import axios from 'axios';
import UIkit from 'uikit';
import { schema_add_pointofsale } from '@/../../validation_schemas/pointofsales.mjs';
const posList = ref([]);
const pos_edit = ref(null);

onMounted(async ()=>{
    fetchPointOfSales();
});

let lastEditPOSId=null;
let posObj = ref({});

const editPosModal = (pos_id)=>{
    lastEditPOSId = pos_id;
    posObj.value = posList.value.find((pos)=>{return pos._id==lastEditPOSId});
    pos_edit.value = unref(posObj.value.pos_name);
    UIkit.modal('#modalEditPOS',{}).show();
}

const editPos = async (values)=>{

    try{
        const res = await axios.put(`/api/v1/pointofsales/${lastEditPOSId}`,values);
        await fetchPointOfSales();
        UIkit.notification("<span uk-icon='icon: check'></span> Point de vente modifié avec succès",{status:'success',pos:'bottom-right'});
    }
    catch(err){
        console.error(err);
        UIkit.notification("<span uk-icon='icon: close'></span> Erreure lors de la modification du point de vente",{status:'danger',pos:'bottom-right'});
    }

    UIkit.modal('#modalEditPOS').hide();
}

const deletePos = async (pos_id)=>{
    try{
        await UIkit.modal.confirm('Voulez-vous vraiment retirer ce point de vente?');
        
        try{
            const res = await axios.delete(`/api/v1/pointofsales/${pos_id}`);
            await fetchPointOfSales();
        }
        catch(err){
            console.error(err);
        }
    }
    catch(err){}    
}

const fetchPointOfSales = async ()=>{
    try{
        const res = await axios.get('/api/v1/pointofsales');
        posList.value = res.data;
    }
    catch(err){
        console.error(err);
    }
}
</script>

<template lang="pug">
table#pos_table.uk-table.uk-table-small.uk-table-striped.uk-table-hover
    thead
        tr
            th.uk-table-expand Nom
            th
    tbody
        template(v-for="pos in posList")
            tr
                td {{ pos.pos_name }}
                td.uk-text-right
                    a(href="" @click.prevent="editPosModal(pos._id)" uk-tooltip="Modifier")
                        span(uk-icon="pencil")
                    a(href="" @click.prevent="deletePos(pos._id)" uk-tooltip="Retirer")
                        span(uk-icon="trash")

div#modalEditPOS(uk-modal="bg-close:false;esc-close:false;")
    div.uk-modal-dialog
        Form(@submit="editPos" :validation-schema="schema_add_pointofsale" v-slot="{ resetForm, meta, validate}")
            div.uk-modal-body
                h2.uk-modal-title Modifier un point de vente
                p
                    Field(name="pos_name" v-slot="{ field, errorMessage }" v-model="pos_edit")
                        div.uk-margin
                            label.uk-form-label Nom du point de vente *
                            div.uk-form-controls                                
                                input.uk-input(type="text" placeholder="" :class="{'uk-form-danger': errorMessage}" v-bind="field")
                                div.dd_field_error {{ errorMessage }}

            div.uk-modal-footer.uk-text-right
                button.uk-button.uk-button-default.uk-modal-close.uk-margin-small-right(type="button") Annuler
                button.uk-button.uk-button-primary(type="submit") Sauvegarder
</template>