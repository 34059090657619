<script setup>
import { onMounted, ref,unref, watch, toRef } from 'vue'
import { Field, Form, configure,useField } from 'vee-validate';

const input_field = ref();

const props = defineProps({
    value:{
        type:String,
        default:''
    },
    name: {
        type: String,
        required: true,
    },
    label:{
        type: String,
        required:false,
        default:'Téléphone (1) *'
    },
    disabled:{
        type:Boolean,
        default:false
    }
});
const emit = defineEmits(['update:modelValue'])
const name = toRef(props, 'name');
const telephone_number_formated = ref('');
const user_input = ref('');

const {
    value: telephone_number,
    errorMessage,
    handleBlur,
    handleChange,
    meta,
    setErrors,
    errors 
} = useField(name, undefined, {
    initialValue: props.value,
});

watch(telephone_number,(new_value)=>{

    telephone_number_formated.value = format(new_value);
})

watch(user_input,(new_value)=>{

    emit('update:modelValue',new_value.value)
})


/**
 * 
 * @param {*} event 
 */
const isNumericInput = (event) => {
	const key = event.keyCode;
	return ((key >= 48 && key <= 57) || // Allow number line
		(key >= 96 && key <= 105) // Allow number pad
	);
};


/**
 * 
 * @param {*} event 
 */
const isModifierKey = (event) => {
	const key = event.keyCode;
	return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
		(key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
		(key > 36 && key < 41) || // Allow left, up, right, down
		(
			// Allow Ctrl/Command + A,C,V,X,Z
			(event.ctrlKey === true || event.metaKey === true) &&
			(key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
		)
};


/**
 * 
 * @param {*} event 
 */
const enforceFormat = (event) => {
	// Input must be of a valid number format or a modifier key, and not longer than ten digits
	if(!isNumericInput(event) && !isModifierKey(event)){
		event.preventDefault();
	}
    else if(event.target.value.replace(/\D/g,'').length > (10-1) && !isModifierKey(event)){
        event.preventDefault();
    }
};

const getCaretPosition = e => e && e.selectionStart || -1;


/**
 * 
 * @param {*} input 
 */
const format = (input)=>{
    let ret = '';
    input = input.replace(/\D/g,'').substring(0,10); // First ten digits of input only
	const areaCode = input.substring(0,3);
	const middle = input.substring(3,6);
	const last = input.substring(6,10);
    
	if(input.length > 6){
        ret = `(${areaCode}) ${middle} - ${last}`;
    }
	else if(input.length > 3){
        ret = `(${areaCode}) ${middle}`; 
    }
	else if(input.length > 0){
        ret = `(${areaCode}`;
    }

    return ret;
}

/**
 * 
 * @param {*} event 
 */
const formatToPhone = (event) => {
    console.log('event',event)
    let caretOffset = 0;

    let caretPosition = getCaretPosition(event.target)
    const input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
    telephone_number.value = input;
	
    if(isModifierKey(event)) {return;}
	  
	const target = event.target;
	const areaCode = input.substring(0,3);
	const middle = input.substring(3,6);
	const last = input.substring(6,10);
    
	if(input.length > 6){
        target.value = `(${areaCode}) ${middle} - ${last}`;
        
        if(caretPosition > 9){
            caretOffset = 3;
        }
        else if(caretPosition > 5){
            caretOffset = 0;
        }
        else if(caretPosition > 1){
            caretOffset = 0;
        }
    }
	else if(input.length > 3){
        target.value = `(${areaCode}) ${middle}`; 
        
        if(caretPosition > 4){
            caretOffset = 2;
        }
        else if(caretPosition > 5){
            caretOffset = 0;
        }
        else if(caretPosition > 1){
            caretOffset = 0;
        }
    }
	else if(input.length > 0){
        target.value = `(${areaCode}`;

         if(caretPosition > 5){
            caretOffset = 0;
        }
        else if(caretPosition > 5){
            caretOffset = 0;
        }
        else if(caretPosition > 0){
            caretOffset = 1;
        }
    }

    caretPosition += caretOffset;
    event.target.setSelectionRange(caretPosition,caretPosition);
};
</script>

<template lang="pug">


div.uk-width-1-5
    label.uk-form-label {{ props.label }}
    div.uk-form-controls
        input.uk-input(ref="input_field" :name="props.name" type="text" :class="{'uk-form-danger': errorMessage}" @keydown="enforceFormat($event)" @keyup="formatToPhone($event)" v-model="telephone_number_formated" :disabled="props.disabled")
div.dd_field_error {{ errorMessage }}
</template>