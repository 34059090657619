<script setup>
import { onMounted, ref,unref, watch } from 'vue'
import { Field, Form, ErrorMessage,configure } from 'vee-validate';
import axios from 'axios';
import * as yup from 'yup';
import UIkit from 'uikit';
import { schema_edit_member } from '@/../../validation_schemas/members.mjs';
import ReceiptNewMember from '@/components/Receipt_NewMember.vue';
import FieldTelephone from '@/components/FieldTelephone.vue';

const props = defineProps({
    mode:{
        type: String,
    },
    formData:{

    },
    disabled:{
        type: Boolean,
        default: false
    }
});
const form = ref();
const loading = ref(false);
const form_error = ref("");
const memberStatus = ref({});

const automatic_member_number = ref(true);
const member_active = ref(true);

const showAutomaticMember = ref(false)

const defaultValues = {
    "number":"",
    "automatic_number":false,
    "firstname":"",
    "lastname":"",
    "telephone1":"",
    "telephone2":"",
    "address":"",
    "city":"",
    "email":"",
    "active":true
}

onMounted(async ()=>{
    form.value.resetForm({values:defaultValues})
    
    try{
        let res = await axios.get(`/api/v1/settings/numberRange`);
        console.log(res.data)
        showAutomaticMember.value = res.data.value.enabled;

        console.log('showAutomaticMember',showAutomaticMember.value)
    }
    catch(err){
        console.error(err);
    }
})

watch(()=> props.formData,(newValue)=>{
    if(newValue === null){
        form.value.resetForm({values:defaultValues})
    }
    else{
        form.value.resetForm({values:newValue})
    }
})

watch(automatic_member_number,(newval)=>{
    console.log('auytomatic changed',newval)
})

const onSubmit = async (values, {resetForm})=>{
    loading.value = true;
    values.number = Number(values.number);

    try{
        if(props.mode == 'update'){
            const res = await axios.put(`/api/v1/members/`,values);
            UIkit.notification("<span uk-icon='icon: check'></span> Membre modifié avec succès",{status:'success',pos:'bottom-right'});
        }
        else{
            // Default to create
            const res = await axios.post(`/api/v1/members/`,values);
            console.log('CREATE RES:',res.data)
            memberStatus.value = res.data;
            UIkit.modal('#new_member_receipt_modal').show();
            UIkit.notification("<span uk-icon='icon: check'></span> Membre ajouté avec succès",{status:'success',pos:'bottom-right'});
        }
        resetForm({values:defaultValues});
    }
    catch(err){
       
        form_error.value = "error"
       
        if(props.mode == 'update'){
            UIkit.notification("<span uk-icon='icon: close'></span> Erreure lors de la modification du membre",{status:'danger',pos:'bottom-right'});
        }
        else{
            UIkit.notification("<span uk-icon='icon: close'></span> Erreure lors de l'ajout du membre",{status:'danger',pos:'bottom-right'});
        }
    }

    loading.value = false;
}

const generateNumber = async ()=>{
    try{
        let res = await axios.get('/api/v1/members/free_number');
        member_number.value = res.data;
    }
    catch(err){
        console.error(err);
    }
}
</script>

<template lang="pug">
Form(ref="form" @submit="onSubmit" :validation-schema="schema_edit_member" v-slot="{ resetForm, meta, validate}")
    
    div.uk-margin
        div(v-if="mode=='update'")
            label
                Field.uk-checkbox(name="active" type="checkbox" :value="true" :unchecked-value="false" v-slot="field" v-model="member_active" :disabled="props.disabled")
                | &nbsp;Membre Actif
                div.uk-text-muted.uk-text-small.uk-margin-left 
                    | Désactiver le numéro de ce membre. Ce membre ne sera plus dans les rapports
                    br
                    | et les données de ce membre seront effacés.
   
    div.uk-margin            
        Field(name="number" v-slot="{ field, errorMessage }" v-model="member_number")  
            div.uk-width-1-5   
                label.uk-form-label Numéro de membre *
                div.uk-form-controls
                    input.uk-input(type="text" placeholder="" :class="{'uk-form-danger': errorMessage}" :disabled="props.disabled || (showAutomaticMember && automatic_member_number==true)" :readonly="props.mode=='update' || (showAutomaticMember && automatic_member_number)" v-bind="field")
            div.dd_field_error {{ errorMessage }}

        template(v-if="showAutomaticMember")
            div.uk-width-4-5.uk-flex.uk-flex-bottom(v-if="mode!='update'")
                div.uk-flex.uk-flex-column
                    div
                        Field.uk-checkbox(name="automatic_number" type="checkbox" :value="true" :unchecked-value="false" v-slot="field" v-model="automatic_member_number" :disabled="props.disabled")
                        | &nbsp;Automatique
                    div.uk-text-muted.uk-text-small Un numéro sera assigné automatiquement au membre par le système lors de sa création.

    div.uk-margin            
        Field(name="firstname" v-slot="{ field, errorMessage }")
            div.uk-width-2-5
                label.uk-form-label Prénom *
                div.uk-form-controls
                    input.uk-input(type="text" placeholder="" :class="{'uk-form-danger': errorMessage}" v-bind="field" :disabled="!member_active || props.disabled")
            div.dd_field_error {{ errorMessage }}
    
    div.uk-margin            
        Field(name="lastname" v-slot="{ field, errorMessage }")
            div.uk-width-2-5
                label.uk-form-label Nom *
                div.uk-form-controls
                    input.uk-input(type="text" placeholder="" :class="{'uk-form-danger': errorMessage}" v-bind="field" :disabled="!member_active || props.disabled")
            div.dd_field_error {{ errorMessage }}
    
    div.uk-margin 
        FieldTelephone(name="telephone1" :disabled="!member_active || props.disabled")
    
    div.uk-margin   
        FieldTelephone(name="telephone2" label="Téléphone (2)" :disabled="!member_active || props.disabled")      

    div.uk-margin     
        Field(name="address" v-slot="{ field, errorMessage }")
            div.uk-width-2-5
                label.uk-form-label Addresse
                div.uk-form-controls
                    input.uk-input(type="text" placeholder="" :class="{'uk-form-danger': errorMessage}" v-bind="field" :disabled="!member_active || props.disabled")
            div.dd_field_error {{ errorMessage }} 

    div.uk-margin         
        Field(name="city" v-slot="{ field, errorMessage }")
            div.uk-width-2-5
                label.uk-form-label Ville *
                div.uk-form-controls
                    input.uk-input(type="text" placeholder="" :class="{'uk-form-danger': errorMessage}" v-bind="field" :disabled="!member_active || props.disabled")
            div.dd_field_error {{ errorMessage }}       

    div.uk-margin         
        Field(name="email" v-slot="{ field, errorMessage }")
            div.uk-width-2-5
                label.uk-form-label Courriel
                div.uk-form-controls
                    input.uk-input(type="text" placeholder="" :class="{'uk-form-danger': errorMessage}" v-bind="field" :disabled="!member_active || props.disabled") 
            div.dd_field_error {{ errorMessage }}
                
    hr

    div.uk-width-1-1
        button.uk-button.uk-button-primary.uk-width-1-2(type="submit" :disabled="!meta.valid || !meta.dirty || loading")
            template(v-if="loading")
                div(uk-spinner)
            template(v-else)
                | Enregistrer
        button.uk-button.uk-button-link.uk-width-1-2(type="button" @click="resetForm" :disabled="!meta.dirty || loading") Annuler

div#new_member_receipt_modal(uk-modal="bg-close:false;esc-close:false;")
    div.uk-modal-dialog.uk-modal-body
        button.uk-modal-close-default(type="button" uk-close)
        ReceiptNewMember(:memberStatus="memberStatus")
</template>