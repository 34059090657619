import { defineStore } from 'pinia';
import { onMounted, ref } from 'vue';
import axios from 'axios';

export const useUsersStore = defineStore('users', () => {

    const currentUser = ref(null);

    onMounted(() => {

        fetchUserInfo();
    })

    const fetchUserInfo = async ()=>{
        console.log('fetchUserInfo')
        let res = null;

        try{
            res = await axios.get('/api/v1/users/current');
            console.log('users info:',res.data);
            currentUser.value = res.data;
        }
        catch(err){
            alert(err);
        }
    }

    const isUser = ()=>{
        if(currentUser.value){
            return currentUser.value.group_type == 'admin' || currentUser.value.group_type == 'user'
        }
        return false;
    }
    
    const isAdmin = ()=>{
        if(currentUser.value){
            return currentUser.value.group_type == 'admin';
        }
        return false;
    }

    const isPosUser = ()=>{
        if(currentUser.value){
            return currentUser.value.group_type == 'posuser';
        }
        return false;
    }


    return { currentUser, isUser, isAdmin, isPosUser };
});