<script setup>
import { onMounted, ref,unref, watch, toRef} from 'vue'
import { Field, Form, ErrorMessage,configure } from 'vee-validate';
import axios from 'axios';
import * as yup from 'yup';
import UIkit from 'uikit';

const form_error = ref("");
const form = ref();

const validationSchema = yup.object({
    "message":yup.string()
        .trim()
});

const onSubmit = async (values,{resetForm})=>{   
    let data = {
        key:'receiptMessage',
        value:values.message
    }
   
    try{
        const res = await axios.post(`/api/v1/settings/`,data);
        form.value.resetForm({values:{message:values.message}});
        UIkit.notification("<span uk-icon='icon: check'></span> Message enregistré avec succès",{status:'success',pos:'bottom-right'});
        form_error.value = '';
    }
    catch(err){
        console.error(err);
        UIkit.notification("<span uk-icon='icon: close'></span> Erreure lors de l'enregistrement du message",{status:'danger',pos:'bottom-right'});
    }
}

onMounted(async ()=>{
    try{
        const res = await axios.get(`/api/v1/settings/receiptMessage`);        
        form.value.resetForm({values:{message:res.data.value}});
    }
    catch(err){
        console.error(err);        
    }
})
</script>

<template lang="pug">
Form(@submit="onSubmit" ref="form" :validation-schema="validationSchema" v-slot="{resetForm, meta, validate }")
    
    Field(name="message" v-slot="{ field, errorMessage }")
        div.uk-margin
            label.uk-form-label Message affiché au bas du recu
            div.uk-form-controls
                textarea.uk-textarea(:class="{'uk-form-danger': errorMessage}" v-bind="field")
    
    div
        div.uk-alert-danger(uk-alert v-if="form_error.length")
            a.uk-alert-close(uk-close)
            | {{ form_error }}  
        button.uk-button.uk-button-primary.uk-margin-right(type="submit" :disabled="!meta.valid||!meta.dirty") Enregistrer
        button.uk-button.uk-button-link(type="button" @click="resetForm" :disabled="!meta.dirty") Annuler
        
</template>