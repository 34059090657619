<script setup>
import { onMounted, ref,unref, watch, toRef} from 'vue'
import axios from 'axios';
import SearchMember from '@/components/SearchMember.vue'
import Receipt from '@/components/Receipt.vue'
import Card_MemberInformation from '@/views/ViewMemberPaymentMgmt/Manage_MemberInformation.vue';

const memberStatus = ref({});
const memData = ref();

const print = async (memberData)=>{
        memData.value = memberData;
    // Get latest transaction for this member

    if(memberData===null){
        memberStatus.value = null;
    }
    else{
        try{
            const response = await axios.get(`/api/v1/members/status/${memberData._id}`);
            memberStatus.value = response.data[0];
        }
        catch(err){
            console.error(err);
        }
    }
 
}
</script>

<template lang="pug">
div.uk-width-1-1
    ul.uk-breadcrumb 
        li
            span Membres 
        li
            span Informations

div(uk-grid)
    div.uk-width-1-1
        div.uk-card.uk-card-default.uk-card-body
            div.uk-width-2-5.uk-margin            
                SearchMember(@searchResults="print")

    div.uk-width-1-2
        Card_MemberInformation(:member="memData")
    div.uk-width-1-2
        div.uk-card.uk-card-default.uk-card-body
            Receipt(:memberStatus="memberStatus")
</template>