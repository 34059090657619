<script setup>
import { onMounted, ref, unref, watch, computed, toRef } from 'vue'
import { Field, Form, ErrorMessage, configure, useField } from 'vee-validate';
import axios from 'axios';

const amount = ref(0);
const modeCustom = ref(false);
const btnValues = ref([]);

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    value: {
        type: Number,
        default: 0
    },
    name: {
        type: String,
        required: true,
    },
    disabled:{
        type: Boolean,
        default: false
    }
});

const name = toRef(props, 'name');
const value = toRef(props, 'value');

const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
    meta,
} = useField(name, undefined, {
    initialValue: props.value,
});

const btnClick = (va) => {
    if (va == 'other') {
        modeCustom.value = true;
    }
    else {
        modeCustom.value = false;
        inputValue.value = va;
    }
}




onMounted(async ()=>{
    try{
        let res = await axios.get('/api/v1/settings/keypadValues/');

        console.log('get setttings',res.data)
        btnValues.value = res.data.value.split(',').map((num)=>Number(num));
        console.log(' btnValues.value', btnValues.value)
    }
    catch(err){
        console.error(err);
    }
})
</script>

<template lang="pug">
div.uk-width-1-1.uk-margin
    div(uk-grid)
        div.uk-width-1-5
            div.uk-grid-collapse(uk-grid)
                div.uk-width-1-1
                    label.uk-form-label Montant du dépot
                    div.uk-inline
                        span.uk-form-icon.uk-form-icon-flip $
                        input#keypad_amount.uk-input.ds-ro-input(v-model.number="inputValue" :readonly="!modeCustom"  :class="{'uk-form-danger': errorMessage}" )
                div.uk-width-1-1
                    div.dd_field_error {{ errorMessage }}
        div#keypad_buttons.uk-width-4-5.uk-flex.uk-flex-bottom.uk-flex-wrap.dd-keypad 
            template(v-for="btnValue in btnValues")
                button.uk-button.uk-button-primary.uk-margin-left(@click.prevent="btnClick(btnValue)" :disabled="props.disabled") {{btnValue}}$
            button.uk-button.uk-button-primary.uk-margin-left(@click.prevent="btnClick('other')" :disabled="props.disabled") Autre
</template>


<style scoped>
.ds-ro-input:read-only{
    background:#0001;
} 

.dd-keypad > button{
    width:115px;
    margin-bottom:10px;
}
</style>