<script setup>
import { onMounted, ref,unref, watch, toRef } from 'vue'
import { Field, Form, configure, useField } from 'vee-validate';
import Datepicker from '@vuepic/vue-datepicker';
import config from '@/../../../../config_public.mjs';

const props = defineProps({
    value:{
        type:String,
        default:''
    },
    name: {
        type: String,
        required: false,
    },
    type: {
        type: String,
        required: false,
        default:'date'
    },
    label:{
        type: String,
        required:false,
        default:'Date du dépot'
    },
    disabled:{
        type: Boolean,
        default: false
    }
});
const emit = defineEmits(['update:modelValue'])
const name = toRef(props, 'name');

const {
    value: date,
    errorMessage,
    handleBlur,
    handleChange,
    meta,
    setErrors,
    errors 
} = useField(name, undefined, {
    initialValue: props.value,
});

watch(date,(new_value)=>{
    console.log('DATE CHANGED',new_value);
    emit('update:modelValue',new_value)
})
const getTimePickerEnabled = ()=>{
  
    let ret = true;
  
    if(props.type == 'drawings' || props.type == 'winners' || props.type == 'date_selector'){
        ret = false;
    }
    return ret;
}
const getDisabledWeekDays=()=>{
    let days = [];
    
    if(props.type == 'drawings' || props.type == 'winners'){
        days = [0,1,3,4,5,6];
    }

    return days;
}

const getCloseOnAutoApply= ()=>{

    let ret = false;

    if(props.type == 'drawings' || props.type == 'date_selector' || props.type == 'winners'){
        ret = true;
    }

    return ret;
}

const getModelType = ()=>{
    let ret = "yyyy-MM-dd HH:mm";

    if(props.type == 'drawings' || props.type == 'winners' || props.type == 'date_selector'){
        ret = "yyyy-MM-dd";
    }

    return ret;
}

const getMaxDate=()=>{
    
    let maxDate = new Date();

    if(props.type == 'drawings' || props.type == 'date_selector'){
        maxDate = null;
    }
    console.log('getMaxDate',maxDate)
    return maxDate;
}

const getMinDate =()=>{
    // let minDate = new Date();
    // if(props.type != 'drawings'){
    //     minDate = null;
    // }
    // console.log('MINDATE:',minDate)
    // return minDate;
}
const startTime = ref({ hours: 0, minutes: 0 });

const showNowButton = ()=>{
    if(props.type == 'drawings' || props.type == 'winners'){
        return false;
    }
    return true;
}

const getFormat = ()=>{
    let format = config.general.formats.datetime_view;
    switch(props.type){
        case 'drawings':
        case 'winners':
            format = config.general.formats.end_date_view;
            break;
        case 'date_selector':
            format = config.general.formats.datetime_view;
            break;
        default:

            break;    
    }

    return format;
}
</script>

<template lang="pug">
div
    label.uk-form-label {{ props.label }}
    Datepicker(v-model="date" 
        input-class-name="dd-datepicker uk-input" 
        position="left" 
        auto-apply 
        :format="getFormat()"
        :close-on-auto-apply="getCloseOnAutoApply()"
        :start-time="startTime"
        :enable-time-picker="getTimePickerEnabled()"
        :disabled-week-days="getDisabledWeekDays()"
        :max-date="getMaxDate()"
        :min-date="getMinDate()"
        :disabled="props.disabled"
        :show-now-button="showNowButton()"
        :timezone="'America/Moncton'"
    )
        template( #now-button="{selectCurrentDate}")
            button.uk-button.uk-button-primary.uk-button-small.dd-now-button(type="button" @click="selectCurrentDate()")
                | Maintenant
</template>

<style scoped>
.dd-now-button{
    margin-bottom:6px;
}
</style>


<style>
.dd-datepicker{
    border-radius: 0 !important;
    height:40px;
}
</style>