<script setup>
import { onMounted, ref,unref, watch, toRef} from 'vue'
import SearchMember from '@/components/SearchMember.vue';
import MemberManage from '@/components/MemberManage.vue';
import UIkit from 'uikit';
import axios from 'axios';
import {DateTime} from 'luxon';
import Calendar from '@/components/Calendar.vue';
import config from '@/../../../../config_public.mjs';

const data = ref([]);
const selectedMembers = ref();
const draw_date = ref();
const showNotFollowedMembers = ref(false);
const selectedPointOfSale = ref('all');
const posList = ref();
const loading = ref(false);

const member_list = ref([]);

onMounted(async ()=>{
    await fetch_data();
    await fetch_members();

    // Load POS
    try{
        const res = await axios.get(`/api/v1/pointofsales/`);
        posList.value = res.data;
    }
    catch(err){
        console.error(err);
    }


    UIkit.util.on('#memberManageModal','beforeshow',()=>{
        // alert('beforeshow')
        
    })

    UIkit.util.on('#memberManageModal','shown',()=>{
        // alert('shown')
        var myDiv = document.querySelector('#memberManageModal .uk-modal-body');
        myDiv.scrollTop = 0;
    })

    UIkit.util.on('#memberManageModal','hidden',()=>{
        // alert('hidden')
        selectedMembers.value = null;
    })
})

const fetch_members = async ()=>{
    try{
        let res = await axios.get('/api/v1/members?by_filter=creationDate')
        member_list.value = res.data;
    }
    catch(err){
        console.error(err);
    }
}

const fetch_data = async ()=>{
    loading.value = true;
    try{
        let da = DateTime.fromJSDate(draw_date.value).toISO();
        let res = await axios.get(`/api/v1/members/manage/dashboard?draw_date=${da}&show_not_followed_only=${showNotFollowedMembers.value}&pos=${selectedPointOfSale.value}`);
        data.value = res.data;
    }
    catch(err){
        alert(err);
    }
    loading.value = false;
}
watch(draw_date,async (new_val)=>{
    console.log('new_val',new_val);
    await fetch_data();
})

watch(showNotFollowedMembers,async (new_val)=>{
    console.log('new_val',new_val);
    await fetch_data();
})

watch(selectedPointOfSale,async (new_val)=>{
    console.log('new_val',new_val);
    await fetch_data();
})

// TODO add to utilities functions
const convertReminder = (reminder)=>{
    switch(reminder){
        case '4weeks':
            return '4 semaines';
        case '3weeks':
            return '3 semaines';
        case '2weeks':
            return '2 semaines';
        case '1week':
            return '1 semaine';
        default:
            return 'Aucun'
    }
}

const showMemberDetails = async (memberId)=>{

    try{
        
        UIkit.modal('#memberManageModal').show();
        selectedMembers.value = null;
        let res = await axios.get(`/api/v1/members/${memberId}`);
        selectedMembers.value = res.data;
    }
    catch(err){
        console.error(err);
    }
}

let reportWindow = null;
const printReport = ()=>{
    let da = DateTime.fromJSDate(draw_date.value).toISO();
    reportWindow = window.open(`/api/v1/reports/generate/members_management_overview/?draw_date=${da}&show_not_followed_only=${showNotFollowedMembers.value}`,"reportViewer","titlebar=no,toolbar=no,menubar=no,location=no,height=700,width=900");
    reportWindow.print();  
}

const unfollowMember = async (member_id)=>{
    try{
        let da = DateTime.fromJSDate(draw_date.value).toISO();
        let res = await axios.put(`/api/v1/members/manage/parameters/`,{member_id,manage:false,reminder:'none',payment_method:'other'});
        data.value = res.data;
        await fetch_data();
    }
    catch(err){
        alert(err);
    }
}
const followMember = async (member_id)=>{
    try{
        let da = DateTime.fromJSDate(draw_date.value).toISO();
        let res = await axios.put(`/api/v1/members/manage/parameters/`,{member_id,manage:true,reminder:'1week',payment_method:'other'});
        data.value = res.data;
        await fetch_data();
    }
    catch(err){
        alert(err);
    }
}
const snoozeMember = async (member_id)=>{
    try{
        let res = await axios.put(`/api/v1/members/manage/snooze/${member_id}`);
        await fetch_data();
    }
    catch(err){
        alert(err);
    }
}
const unsnoozeMember = async (member_id)=>{
    try{
        let res = await axios.delete(`/api/v1/members/manage/snooze/${member_id}`);
        await fetch_data();
    }
    catch(err){
        alert(err);
    }
}

let color_index = 4;
let last_group_id='';

const label_color_class = (group_id)=>{
    
    if(last_group_id != group_id){
    
        color_index++;
    
        if(color_index > 4){
            color_index = 0;
        }

        last_group_id = group_id;
    }
    let ret='dd-group-color_'+ color_index;

    return ret;
}

// TODO this should be in utilities module
const formatTelephone = (telephone)=>{
    
    let ret = telephone;
    
    const areaCode = telephone.substring(0,3);
	const middle = telephone.substring(3,6);
	const last = telephone.substring(6,10);
    
	if(telephone.length > 6){
        ret = `(${areaCode}) ${middle}-${last}`;
    }
	else if(telephone.length > 3){
        ret = `(${areaCode}) ${middle}`; 
    }
	else if(telephone.length > 0){
        ret = `(${areaCode}`;
    }
    return ret;
}

// TODO this should be in a common utils module
const paymentMethods = (method)=>{
    
    switch(method){
        case 'cash':
            return 'Argent';
        case 'interact':
            return 'Interac';
        case 'accesd':
            return 'Virement Bancaire';
        case 'check':
            return 'Chèque';
        case 'other':
            return 'Autre';
    }
}
    


const closeForm = ()=>{
    UIkit.modal('#memberManageModal',{}).hide();    
}

let noteMemberId = null;
let loading_note = ref(false);
const noteData = ref('');
const add_note = async (member_id)=>{
    // alert(`Adding note to ${member_id}`);
    noteMemberId = member_id;
    noteData.value = '';
    UIkit.modal('#addNoteModal').show();
}

const hide_note_modal = ()=>{
    UIkit.modal('#addNoteModal').hide();
}

const addNote = async ()=>{
    loading.value = true;
    loading_note.value = true;
    // const note_elem = document.getElementById(`#note_add_textarea_${noteMemberId}`);
    let values = {};
    // let note = note_elem.value;
    // note_elem.value = "";

    values.member_id = noteMemberId;
    values.note = noteData.value;
    
    try{
        await axios.post('/api/v1/members/manage/notes/',values);
        UIkit.notification("<span uk-icon='icon: check'></span> Note ajouté avec succès",{status:'success',pos:'bottom-right'});
        await fetch_data();

        // UIkit.dropdown(`#note_add_${noteMemberId}`).hide(0)
    }
    catch(err){
        console.error(err);
        UIkit.notification("<span uk-icon='icon: close'></span> Erreure lors de l'ajout de la note",{status:'danger',pos:'bottom-right'});
    }
    
    loading.value = false;
    loading_note.value = false;
    UIkit.modal('#addNoteModal').hide();
}
</script>

<template lang="pug">
div.uk-width-1-1
    ul.uk-breadcrumb 
        li
            span Gestion des paiements 
        li
            span Tableau de bord
//- div.uk-width-1-1(uk-grid)
//-     div.uk-width-1-1
//-         div.uk-card.uk-card-default.uk-card-body
//-             h3.uk-card-title Nouveaux Membres
//-                 span(style='font-style:italic;font-size:0.8em;') &nbsp;(Deux dernières semaines)
//-             div.uk-margin
//-                 table.uk-table.uk-table-small.uk-table-divider.uk-table-hover.uk-table-middle.uk-table-responsive
//-                     thead
//-                         tr
//-                             th Date de création
//-                             th Numéro
//-                             th Nom
//-                             th Ville
//-                     tbody
//-                         template(v-for="member in member_list")
//-                             tr
//-                                 td {{ DateTime.fromISO(member.creationDate).toFormat(config.general.formats.datetime_view) }}
//-                                 td {{ member.number }}
//-                                 td {{ member.firstname }} {{ member.lastname }}
//-                                 td {{ member.city }}
//-                                 td 
//-                                     template(v-if="member.creationUser")
//-                                         | {{ member.creationUser }}
//-                                     template(v-else)
//-                                         | N/D


div.uk-width-1-1(uk-grid)
    div.uk-width-1-1
        div.uk-card.uk-card-default.uk-card-body
            h3.uk-card-title Liste de renouvellement
                button.uk-button.uk-button-primary(@click="printReport()" style="float:right;" :disabled="!draw_date") 
                    span(uk-icon="print" style="margin-right:10px;")
                    | Imprimer
            div.uk-margin
                div(uk-grid)
                    div.uk-width-1-5
                        Calendar(type="drawings" label="Date de rappel" v-model="draw_date")
                    div.uk-width-1-5
                    //-     label Point de vente
                    //-     select.uk-select(v-model="selectedPointOfSale")
                    //-         option(value="all") Tous
                    //-         template(v-for="pos in posList")
                    //-             option(:value="pos._id") {{ pos.pos_name}} 
                    
                    div.uk-width-2-5
                        label Afficher les membres non suivi seulement
                        input.uk-checkbox(type="checkbox" v-model="showNotFollowedMembers")
                    div.uk-width-1-5
                        | Nombre total de membre: {{ data.length }}
            div(style="position:relative;")
                div#loading_overlay.uk-overlay-primary.uk-position-cover(v-if="loading" style="z-index:1000;")
                    div.uk-position-center
                        span(uk-spinner="ratio:2")
                table.uk-table.uk-table-small.uk-table-divider.uk-table-hover.uk-table-middle.uk-table-responsive
                    thead
                        tr
                            th
                            th
                            th Nom
                            th Ville
                            th Méthode de Paiement
                            th Dernier Tirage Éligible
                            //- th Dernier Point de vente
                            th
                    tbody
                        template(v-if="draw_date == null")
                            tr
                                td(colspan="6")
                                    | Choisir une date de rappel
                        template(v-else-if="data.length == 0")
                            tr
                                td(colspan="6")
                                    | Aucun Membre 
                        template(v-else)
                            template(v-for="member in data" :key="member._id")
                                tr(:class="{'dd-group-primary':member.group_primary=='1','dd-group-member':(member.group_id && member.group_primary=='0'),'dd-snooze-member':member.params.snooze}")
                                    td 
                                        template(v-if="member.group_id")
                                            span.uk-label(:class="label_color_class(member.group_id)")
                                                span(uk-icon="users")
                                            
                                    td 
                                        b {{ member.number}} 
                                    td 
                                        a(@click="showMemberDetails(member._id)") {{ member.firstname }} {{ member.lastname }}
                                        br
                                        | {{ formatTelephone(member.telephone1) }} 
                                        br
                                        | {{ formatTelephone(member.telephone2) }}
                                    td {{ member.city}} 
                                    td 
                                        template(v-if="member.params")
                                            | {{ paymentMethods(member.params.payment_method) }}
                                    td {{ member.endDate?DateTime.fromISO(member.endDate).toFormat('yyyy-LL-dd'):'N/D' }}
                                    td 
                                        template(v-if="member.group_primary=='1'")
                                            span.uk-label.uk-label-success Contact Principal
                                    td.uk-text-nowrap 
                                        //- a(@click.prevent="" uk-icon="comments" uk-tooltip="Afficher les notes")
                                        //- div(uk-dropdown="mode: click; pos:bottom-right;") 
                                        //-     ul
                                        //-         template(v-for="note in member.notes")
                                        //-             li {{ note.note }}
                                        a(@click.prevent="add_note(member._id)" uk-icon="comment")
                                        //- a(@click.prevent="" uk-icon="comment" uk-tooltip="Ajouter une note")
                                        //- div(uk-dropdown="mode: click; pos:bottom-right;" :id="`note_add_${member._id}`")
                                        //-     div(uk-grid)
                                        //-         div.uk-width-1-1
                                        //-             textarea.uk-textarea(:id="`#note_add_textarea_${member._id}`")
                                        //-         div.uk-width-1-1
                                        //-             button.uk-button.uk-button-primary.uk-margin-right(@click.prevent="addNote(member._id)") Ajouter Note
                                        //-             button.uk-button.uk-button-link(@click.prevent="UIkit.dropdown(`#note_add_${member._id}`).hide(0)") Annuler
                                        template(v-if="!showNotFollowedMembers")
                                            a(@click.prevent="unfollowMember(member._id)" uk-icon="ban" uk-tooltip="Ne plus suivre ce membre")
                                        template(v-else)
                                            a(@click.prevent="followMember(member._id)" uk-icon="check" uk-tooltip="Suivre ce membre")
                                        
                                        template(v-if="!member.params.snooze")
                                            a(@click.prevent="snoozeMember(member._id)" uk-icon="eye" uk-tooltip="Garder ce membre dans les listes a suivre")
                                        template(v-else)
                                            a(@click.prevent="unsnoozeMember(member._id)" uk-icon="eye-slash" uk-tooltip="Retirer ce membre dans les listes a suivre")
                                
                                tr(v-if="member.notes && member.notes.length")
                                    td(colspan="2")
                                    td(colspan="6")
                                        ul
                                            template(v-for="note in member.notes")
                                                li {{ note.note }}
div#memberManageModal.uk-modal-container(uk-modal="esc-close:false;bg-close:false;")
    div.uk-modal-dialog
        //- button.uk-modal-close-default(type="button" uk-close)
        div.uk-modal-header
            h2.uk-modal-title Gestion
        div.uk-modal-body(uk-overflow-auto)
            MemberManage(:member="selectedMembers")
        div.uk-modal-footer.uk-text-right
            button.uk-button.uk-button-primary(type="button" @click="closeForm")
                | Fermer

div#addNoteModal(uk-modal="esc-close:false;bg-close:false;")
    div.uk-modal-dialog
        div.uk-modal-header Ajouter une note
        div.uk-modal-body(uk-overflow-auto)
            div(uk-grid)
                div.uk-width-1-1
                    textarea.uk-textarea(v-model="noteData")
        div.uk-modal-footer.uk-text-right
            div.uk-width-1-1
                button.uk-button.uk-button-primary.uk-margin-right(@click.prevent="addNote()" :disabled="loading_note") Ajouter Note
                button.uk-button.uk-button-link(@click.prevent="UIkit.modal(`#addNoteModal`).hide()" :disabled="loading_note") Annuler
                                     
</template>

<style>
.dd-group-primary{
    /* background:red; */
}

.dd-group-member{
    /* background:green; */
}

.dd-group-color_0{
    background-color: #1E87F0;
}

.dd-group-color_2{
    background-color: #993955;
}

.dd-group-color_3{
    background-color: #003049;
}

.dd-group-color_4{
    background-color: #F77F00;
}

.dd-group-color_1{
    background-color: #708B75;
}

.dd-snooze-member{
    background-color:#00ff1152;
}
</style>