<script setup>
import { onMounted, ref,unref, watch} from 'vue'
import { Field, Form, ErrorMessage } from 'vee-validate';
import axios from 'axios';
import * as yup from 'yup';
import UIkit from 'uikit';
import {DateTime} from 'luxon';
import { schema_add_user } from '@/../../validation_schemas/users.mjs';

const form = ref();
const form2 = ref();
const userList = ref([]);
let lastEditUserId = null;
const defaultValues = {
    "username":"",
    "password":"",
    "display_name":"",
    "firstname":"",
    "lastname":"",
    "city":"",
    "email":"",
    "group_type":"disabled",
    "last_access":"",
    "allowed_pos":""
}
const defaultValues2 = {"temp_password":''}
onMounted(async ()=>{
    await fetchPOS();
    await fetchUsers({deleted:deletedUserVisible.value});

    
})

const fetchUsers = async (params)=>{
    let showDeleted = false;
    deletedUserBannerVisible = false;
    try{
        if(params && params.deleted){
            showDeleted = true;
        }
        const res = await axios.get(`/api/v1/users${showDeleted?'?deleted=true':''}`);
        userList.value = res.data[0].data;
    }
    catch(err){
        console.error(err);
    }
}

const deleteUser = async (user_id)=>{   

    try{
        await UIkit.modal.confirm("Voulez-vous vraiment retirer cet utilisateur?");

        try{
            const res = axios.delete(`/api/v1/users/${user_id}`);
            await fetchUsers({deleted:deletedUserVisible.value});
            UIkit.notification("<span uk-icon='icon: check'></span> Utilisateur supprimé avec succès",{status:'success',pos:'bottom-right'});
        }
        catch(err){
            console.error(err);
            UIkit.notification("<span uk-icon='icon: close'></span> Erreur lors de la suppression de l'utilisateur",{status:'danger',pos:'bottom-right'});
        }
    }
    catch(err){}

    
}

const groupName = (name)=>{
    switch(name){
        case 'user':
            return 'Utilisateur'
            return 'User'
        case 'admin':
            return 'Administrateur';
            return 'Administrator';
        case 'posuser':
            return 'Utilisateur point de vente';
            return 'Point-of-Sale User';
        case 'disabled':
            return 'Désactivé';
            return 'Disabled';
        default:
            return name;
    }
}
const groupLabelName = (name)=>{
    switch(name){
        case 'user':
            return ''
        case 'admin':
            return {'uk-label-success':true};
        case 'posuser':
            return 'uk-label-warning';
        case 'disabled':
            return 'uk-label-danger';
        default:
            return name;
    }
}


// Modal
let userObj = ref({});
const currentGroup = ref('');

const editUserModal = (user_id)=>{
    lastEditUserId = user_id;
    userObj.value = userList.value.find((user)=>{return user._id==lastEditUserId});
    console.log('userObj',userObj.value)
    
    console.log('form.value',form.value)
    // form.value.setValues({city:userObj.value.username})
    form.value.resetForm({values:{
        username:userObj.value.username,
        display_name:userObj.value.display_name,
        lastname:userObj.value.lastname,
        firstname:userObj.value.firstname,
        city:userObj.value.city,
        email:userObj.value.email,
        group_type:userObj.value.group_type,
        allowed_pos:userObj.value.allowed_pos
    }});
    currentGroup.value = userObj.value.group_type;
    UIkit.modal('#modalEditUser',{}).show();
}
let tempID = null;
const resetUserPasswordModal = (user_id)=>{
    UIkit.modal('#modalResetUserPassword',{}).show();
    tempID = user_id;
}

const resetUserPassword = async (values)=>{
    console.log('VLAUES',values)
    try{
       await axios.put(`/api/v1/users/resetPassword/${tempID}`,values);
       UIkit.notification("<span uk-icon='icon: check'></span> Utilisateur modifié avec succès",{status:'success',pos:'bottom-right'});
       UIkit.modal('#modalResetUserPassword').hide();
    }
    catch(err){
        console.error(err);
        UIkit.notification("<span uk-icon='icon: close'></span> Erreure lors de la modification de l'utilisateur",{status:'danger',pos:'bottom-right'});
    }
}

const editUser = async (values)=>{

    console.log('values:',values)
    try{
        const res = await axios.put(`/api/v1/users/${lastEditUserId}`,values);
        await fetchUsers({deleted:deletedUserVisible.value});
        UIkit.notification("<span uk-icon='icon: check'></span> Utilisateur modifié avec succès",{status:'success',pos:'bottom-right'});
        UIkit.modal('#modalEditUser').hide();
    }
    catch(err){
        console.error(err);
        UIkit.notification("<span uk-icon='icon: close'></span> Erreure lors de la modification de l'utilisateur",{status:'danger',pos:'bottom-right'});
    }
}

const schema_user_password = yup.object({
    "temp_password":yup.string().min(8).required()
})

// const validationSchema = yup.object({
//     "username":yup.string().lowercase().required().min(1).trim().matches(/^[a-z0-9]+$/i,"Le nom d'utilisateur doit contenir des charactères alpha-numérique seulement."),
//     // "password":yup.string().min(8).required(),
//     "display_name":yup.string().required().min(1).trim().matches(/^[a-z0-9\s'-]+$/i,"Le nom affiché doit contenir des charactères alpha-numérique seulement."),
//     "lastname":yup.string().required().min(1).trim(),
//     "firstname":yup.string().required().min(1).trim(),
//     "city":yup.string().trim(),
//     "email":yup.string().required().email().trim()
// });


const formatLastAccess = (time)=>{

    if(time){
        return DateTime.fromISO(time).toLocaleString(DateTime.DATETIME_MED);
    }
    else{
        return 'Jamais';
    }
   
}
const posList = ref([]);

const fetchPOS = async ()=>{
    try{
        const res = await axios.get(`/api/v1/pointofsales/`);
        posList.value = res.data;

        console.log('posList',posList.value)
    }
    catch(err){
        console.error(err);
    }
}

const closeForm = ()=>{
    // Close Modal
    UIkit.modal('#modalEditUser',{}).hide();

    // Reset form
    form.value.resetForm({values:defaultValues})
}
const closeForm2 = ()=>{
    // Close Modal
    UIkit.modal('#modalResetUserPassword',{}).hide();

    // Reset form
    form2.value.resetForm({values:defaultValues2})
}

let deletedUserVisible = ref(false);

const showDeletedUsers = async ()=>{
    deletedUserVisible.value = true;
    deletedUserBannerVisible = false;
    await fetchUsers({deleted:deletedUserVisible.value});
}

const hideDeletedUsers = async ()=>{
    deletedUserVisible.value = false;
    deletedUserBannerVisible = false;
    await fetchUsers({deleted:deletedUserVisible.value});
}

const restoreUser = async (userId)=>{
    
    try{
        const res = await axios.put(`/api/v1/users/restore/${userId}`);
        deletedUserBannerVisible = false;
        await fetchUsers({deleted:deletedUserVisible.value});
    }
    catch(err){
        console.error(err);
    }
}

let deletedUserBannerVisible = false;
// This function assume the data is ordered by user.delete as last in the array.
const showDeletedUsersBanner = (userDisabled)=>{
    if(deletedUserBannerVisible){
        return false;
    }
    if(userDisabled){
        deletedUserBannerVisible = true;
        return true;
    }
    return false;
}
</script>

<template lang="pug">

//- table.uk-table.uk-table-small.uk-table-striped.uk-table-hover
table.uk-table.uk-table-small.uk-table-divider.uk-table-hover.uk-table-middle.uk-table-responsive
    thead
        tr
            th Nom
            th
            th Groupe
            th Point de vente
            th Dernier Accès
            th
    tbody
        template(v-for="user in userList")
            template(v-if="showDeletedUsersBanner(user.deleted)")
                th
                    td(colspan="5")
                        | Utilisateur Retirés
            tr
                td.uk-table-shrink 
                    b {{ user.username }}
                    br
                    | {{ user.email }} 
                td.uk-table-expand {{ user.firstname }} {{ user.lastname }}
                td 
                    span.uk-label(:class="groupLabelName(user.group_type)") {{ groupName(user.group_type) }}
                td {{ user.pos_name }}
                td {{formatLastAccess(user.last_access)}}
                td.uk-table-shrink 
                    template(v-if="user.deleted")
                        div.uk-preserve-width.uk-text-nowrap
                            a(href="" @click.prevent="restoreUser(user._id)" uk-tooltip="Restaurer")
                                span(uk-icon="reply")
                    template(v-else)
                        div.uk-preserve-width.uk-text-nowrap
                            a(href="" @click.prevent="resetUserPasswordModal(user._id)" uk-tooltip="Réinitialiser le mot de passe")
                                span(uk-icon="refresh")
                            a(href="" @click.prevent="editUserModal(user._id)" uk-tooltip="Modifier")
                                span(uk-icon="pencil")
                            a(href="" @click.prevent="deleteUser(user._id)" uk-tooltip="Retirer")
                                span(uk-icon="trash")

hr
template(v-if="deletedUserVisible")
    button.uk-button.uk-button-default(@click="hideDeletedUsers()") Cacher les utilisateurs retirés
template(v-else)
    button.uk-button.uk-button-default(@click="showDeletedUsers()") Afficher les utilisateurs retirés

div#modalResetUserPassword.uk-modal-container(uk-modal="bg-close:false;esc-close:false;")
    div.uk-modal-dialog
        Form(@submit="resetUserPassword" ref="form2" :validation-schema="schema_user_password" v-slot="{ resetForm, meta, validate, errors }")
            div.uk-modal-header
                h2.uk-modal-title Réinitialisation du mot de passe
            div.uk-modal-body(uk-overflow-auto)
                p Changer le mot de passe d'un utilisateur. L'utilisateur devra réinialiser son mot de passe à l'aide du mot de passe temporaire lors du sa prochaine ouverture de session.
                //- p Le mot de passe temporaire est valide 1 heure.
                Field(name="temp_password" v-slot="{ field, errorMessage }")
                    div.uk-margin.uk-width-2-5
                        label.uk-form-label Mot de passe temporaire
                        div.uk-form-controls
                            input.uk-input(type="text" placeholder="" :class="{'uk-form-danger': errorMessage}" v-bind="field")
            div.uk-modal-footer(uk-margin)
                button.uk-button.uk-button-primary.uk-margin-right(type="submit" :disabled="!meta.valid || !meta.dirty")
                    | Enregistrer
                button.uk-button.uk-button-link(type="button" @click="closeForm2")
                    | Annuler

div#modalEditUser.uk-modal-container(uk-modal="bg-close:false;esc-close:false;")
    div.uk-modal-dialog
        Form(@submit="editUser" ref="form" :validation-schema="schema_add_user" v-slot="{ resetForm, meta, validate, errors }")
            
            div.uk-modal-header
                h2.uk-modal-title Modifier un utilisateur
            div.uk-modal-body(uk-overflow-auto)
                //- div {{ errors }}
                Field(name="username" v-slot="{ field, errorMessage }")
                    div.uk-margin.uk-width-2-5
                        label.uk-form-label Nom d'utilisateur *
                        div.uk-form-controls
                            input.uk-input(type="text" placeholder="" :class="{'uk-form-danger': errorMessage}" v-bind="field")
                
                Field(name="display_name" v-slot="{ field, errorMessage }")
                    div.uk-margin.uk-width-2-5
                        label.uk-form-label Nom affiché *
                        div.uk-form-controls
                            input.uk-input(type="text" placeholder="" :class="{'uk-form-danger': errorMessage}" v-bind="field")
            
                Field(name="group_type" v-slot="{ field, errorMessage }" @input="currentGroup = $event.target.value")
                    div.uk-margin.uk-width-2-5
                        label.uk-form-label Groupe
                        div.uk-form-controls
                            select.uk-select(type="text" placeholder="" :class="{'uk-form-danger': errorMessage}" v-bind="field")
                                option(value="disabled") Désactivé
                                option(value="user") Utilisateur
                                option(value="posuser") Utilisateur point de vente
                                option(value="admin") Administrateur
                
                Field(name="allowed_pos" v-slot="{ field, errorMessage }"  v-if="currentGroup == 'posuser'")
                    div.uk-margin.uk-width-2-5
                        label.uk-form-label Point de vente
                        div.uk-form-controls
                            select.uk-select(type="text" placeholder="" :class="{'uk-form-danger': errorMessage}" v-bind="field")
                                template(v-for="pos in posList")
                                    option(:value="pos._id") {{pos.pos_name}}
                
                Field(name="firstname" v-slot="{ field, errorMessage }") 
                    div.uk-margin.uk-width-2-5
                        label.uk-form-label Prénom
                        div.uk-form-controls
                            input.uk-input(type="text" placeholder="" :class="{'uk-form-danger': errorMessage}" v-bind="field")    
                
                Field(name="lastname" v-slot="{ field, errorMessage }")
                    div.uk-margin.uk-width-2-5
                        label.uk-form-label Nom
                        div.uk-form-controls
                            input.uk-input(type="text" placeholder="" :class="{'uk-form-danger': errorMessage}" v-bind="field")    
                
                Field(name="city" v-slot="{ field, errorMessage }")
                    div.uk-margin.uk-width-2-5
                        label.uk-form-label Ville
                        div.uk-form-controls
                            input.uk-input(type="text" placeholder="" :class="{'uk-form-danger': errorMessage}" v-bind="field")   
                
                Field(name="email" v-slot="{ field, errorMessage }")
                    div.uk-margin.uk-width-2-5
                        label.uk-form-label Courriel
                        div.uk-form-controls
                            input.uk-input(type="text" placeholder="" :class="{'uk-form-danger': errorMessage}" v-bind="field")  
                
            div.uk-modal-footer(uk-margin)
                button.uk-button.uk-button-primary.uk-margin-right(type="submit" :disabled="!meta.valid || !meta.dirty")
                    | Enregistrer
                button.uk-button.uk-button-link(type="button" @click="closeForm")
                    | Annuler
                //- div.uk-alert-danger(uk-alert v-if="form_error.length")
                //-     a.uk-alert-close(uk-close)
                //-     | {{ form_error }}
</template>