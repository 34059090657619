import * as yup from 'yup';

export const schema_add_winner = yup.object({
    "member_id":yup.string().required(),
    "draw_date":yup.string().matches(/[0-9\-\s]+/).required(),
    "amount_won":yup.number().required(),
    "amount_week":yup.number().integer().required(),
    "member_paid":yup.boolean().required(),
    "draw_by":yup.string().required(),
    "pos_id":yup.string().required(),
});