<script setup>
import { onMounted, ref,unref, watch} from 'vue'
import axios from 'axios';
import { Field, Form, ErrorMessage,configure } from 'vee-validate';
import * as yup from 'yup';
import UIkit from 'uikit';

const form_error = ref("");
const form = ref();

//TODO use common schema with backend
const validationSchema = yup.object({
    "numbers":yup.string()
        .required()
        .trim()
        .matches(/^([0-9,]+)$/ui,"")
        .test('numberss','Format invalide',function(value,ctx){
            if(value){
                let values = value.split(',').map(x => parseInt(x));
                let out = values.find(x => x%2);
                return (typeof out == 'undefined')?true:false;
            }
            return false;           
        })
});

onMounted(async ()=>{
    try{
        const res = await axios.get(`/api/v1/settings/keypadValues`);        
        form.value.resetForm({values:{numbers:res.data.value}});
    }
    catch(err){
        console.error(err);        
    }
})

const onSubmit = async (values,{resetForm})=>{
   
    let data = {
        key:'keypadValues',
        value:values.numbers
    }
   
    try{
        const res = await axios.post(`/api/v1/settings/`,data);
        form.value.resetForm({values:{numbers:values.numbers}});
        form_error.value = '';
        UIkit.notification("<span uk-icon='icon: check'></span> Paramètre modifié avec succès",{status:'success',pos:'bottom-right'});
        
    }
    catch(err){
        console.error(err);
        form_error.value = err.message;
        UIkit.notification("<span uk-icon='icon: check'></span> Erreur lors de la modification du paramètre",{status:'danger',pos:'bottom-right'});
    }
}
</script>


<template lang="pug">
Form(@submit="onSubmit" ref="form" :validation-schema="validationSchema" v-slot="{ resetForm, meta, validate}")
    
    Field(name="numbers" v-slot="{ field, errorMessage }")
        div.uk-margin
            label.uk-form-label Nombres, séparés par des virgules
            div.uk-form-controls
                input.uk-input(type="text" placeholder="" :class="{'uk-form-danger': errorMessage}" v-bind="field") 
                | {{ errorMessage }}   
    
    p(uk-margin)
        button.uk-button.uk-button-primary.uk-margin-right(type="submit" :disabled="!meta.valid||!meta.dirty")
            | Enregistrer
        button.uk-button.uk-button-link(type="button" @click="resetForm" :disabled="!meta.dirty")
            | Annuler
        div.uk-alert-danger(uk-alert v-if="form_error.length")
            a.uk-alert-close(uk-close)
            | {{ form_error }}
</template>