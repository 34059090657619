<script setup>
import { onMounted, ref,unref, watch} from 'vue'
import SearchMember from '@/components/SearchMember.vue'
import FormMember from '@/components/FormMember.vue';
import axios from 'axios';

const searchResults = ref(null);


const searchResultsHandler = async (data)=>{
    if(data !== null){
        try{
            let res = await axios.get(`/api/v1/members/${data._id}`);
            searchResults.value = res.data;
            console.log('searchResultsHandler',searchResults.value)
        }
        catch(err){
            console.error(err);
        }
    }
    else{
        searchResults.value = null;
    }    
}

watch(searchResults,()=>{

    console.log('SERARCH RESULT CHANGED')
})
</script>

<template lang="pug">
div.uk-width-1-1
    ul.uk-breadcrumb 
        li
            span Membres 
        li
            span Modifier

div(uk-grid)
    div.uk-width-1-1
        div.uk-card.uk-card-default.uk-card-body
            div.uk-width-2-5.uk-margin
                SearchMember(@searchResults="searchResultsHandler")
        
    div.uk-width-1-1(:class="{'dd-disabled-card': searchResults==null}")
        div.uk-card.uk-card-default.uk-card-body
            FormMember(:formData="searchResults" mode="update" :disabled="searchResults==null")
</template>

<style>
.dd-disabled-card{
    opacity:0.5;
}
</style>