import * as yup from 'yup';

export const schema_add_user = yup.object({
    "username":yup.string().lowercase().required().min(2).trim().matches(/^[a-z0-9]+$/i,"Le nom d'utilisateur doit contenir des charactères alpha-numérique seulement."),
    "password":yup.string().min(8),
    "display_name":yup.string().required().min(2).trim(),
    // "display_name":yup.string().required().min(2).trim().matches(/^[a-z0-9\s'-\.]+$/i,"Le nom affiché doit contenir des charactères alpha-numérique seulement."),
    "firstname":yup.string().trim(),
    "lastname":yup.string().trim(),
    "city":yup.string().notRequired().trim(),
    "email":yup.string().email().notRequired().trim(),
    "group_type": yup.string().required(),
    // "last_access":yup.string().required().trim(),
    "allowed_pos":yup.string().trim().nullable()
});