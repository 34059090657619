<script setup>
import { onMounted, ref,unref, watch, toRef} from 'vue'
import { Field, Form, ErrorMessage,configure } from 'vee-validate';
import * as yup from 'yup';
import axios from 'axios';
import SearchMember from '@/components/SearchMember.vue'
import UIkit from 'uikit';
import Calendar from '@/components/Calendar.vue'
import {DateTime, Settings} from 'luxon';

const winnerPerPage = 10;
const members = ref({});
const winnersCount = ref(0);
const currentPage = ref(1);
const form = ref();
const loading = ref(false)

Settings.defaultZone = "utc";

const validationSchema = yup.object({
    "member_id":yup.string().required(),
    "draw_date":yup.string().matches(/[0-9\-\s]+/).required(),
    "amount_won":yup.number().required(),
    "amount_week":yup.number().integer().required(),
    "member_paid":yup.boolean().required(),
    "draw_by":yup.string().required(),
    "pos_id":yup.string().required(),
})


onMounted(async ()=>{
    await fetchWinners();
})

const fetchWinners = async (page)=>{
    loading.value = true;
    currentPage.value = (typeof page == 'undefined')?1:page;
    
    try{
        const res = await axios.get(`/api/v1/winners/?page=${page}&count=${winnerPerPage}`);
        members.value = res.data[0].data.map((mem)=>{mem.draw_date = DateTime.fromISO(mem.draw_date).toFormat('yyyy-LL-dd'); mem.pos_id = mem.pos_id==null?'none':mem.pos_id; return mem;});
        winnersCount.value = Math.ceil((res.data[0].count[0].count)/winnerPerPage);
    }
    catch(err){
        console.error(err);
        form_error.value = "error"
    }
    loading.value = false;
}

const previousPage = ()=>{
    currentPage.value = currentPage.value - 1 > 0?currentPage.value-1:1;
    fetchWinners(currentPage.value);
}
const nextPage = ()=>{
    currentPage.value = currentPage.value + 1 < winnersCount.value?currentPage.value+1: winnersCount.value;
    fetchWinners(currentPage.value);
}


const removeWinner = async (member_id)=>{
    loading.value = true;
    try{
        await UIkit.modal.confirm("Voulez-vous vraiment retirer ce gagnant?")

        try{
            const res = await axios.delete(`/api/v1/winners/${member_id}`);
            fetchWinners(currentPage.value);
        }
        catch(err){
            console.error(err);
            form_error.value = "error"
        }
    }
    catch(err){
        console.log('ERR: ',err)
    }
    loading.value = false;
}

const posList = ref([]);
onMounted(async ()=>{
    try{
        const res = await axios.get(`/api/v1/pointofsales/`);
        posList.value = res.data;

        console.log('posList',posList.value)
    }
    catch(err){
        console.error(err);
    }
})

// Modal

let lastEditWinnerId=null;
// let winnerObj = ref({});

const editWinnerModal = (member_id)=>{

    loading.value = true;
    lastEditWinnerId = member_id;
    
    // winnerObj.value = members.value.find((member)=>{return member._id==lastEditWinnerId});
    const winnerData = members.value.find((member)=>{return member._id==lastEditWinnerId});    
    
    // console.log('EDIT MODAL:',winnerObj.value)
    console.log('winnerData',winnerData)
    form.value.resetForm({values:winnerData})

    // console.log('winnerObj',winnerObj.value)
    UIkit.modal('#modalEditWinner',{}).show();
    loading.value = false;
}


const onSubmit = async (values)=>{
    loading.value = true;
    console.log('values:',values)
    try{
        const res = await axios.put(`/api/v1/winners/${lastEditWinnerId}`,values);
        await fetchWinners();
    }
    catch(err){
        console.error(err);
    }

    UIkit.modal('#modalEditWinner').hide();
    loading.value = false;
}

const closeModal = ()=>{
    UIkit.modal('#modalEditWinner').hide();
}
</script>

<template lang="pug">
div.uk-width-1-1
    ul.uk-breadcrumb 
        li
            span Gagnants Archivés 
        li
            span Liste

div(uk-grid)
    div.uk-width-1-1
        div.uk-card.uk-card-default.uk-card-body
            div#loading_overlay.uk-overlay-primary.uk-position-cover(v-if="loading" style="z-index:1000;")
                div.uk-position-center
                    span(uk-spinner="ratio:2")
            div.uk-margin
                table.uk-table.uk-table-small.uk-table-divider.uk-table-hover.uk-table-middle.uk-table-responsive
                    thead
                        tr
                            th.uk-table-shrink Date
                            th.uk-table-shrink Numéro
                            th Nom
                            th.uk-table-shrink Montant gagné
                            th Pigé par
                            th Vendu par
                            th.uk-table-shrink Montant de la semaine
                            th
                    tbody
                        template(v-for="member in members")
                            tr  
                                td 
                                    div.uk-text-nowrap
                                        b {{ DateTime.fromISO(member.draw_date).toFormat('yyyy-LL-dd') }}
                                    template(v-if="member.member_paid")
                                        span.uk-label.uk-label-success(style="opacity:0.8;") Gagnant
                                    template(v-else)
                                        span.uk-label.uk-label-danger(style="opacity:0.8;") Non Gagnant
                                td {{ member.number }}
                                td 
                                    b {{ member.firstname }} {{ member.lastname }}
                                    br
                                    i {{ member.city }}
                                td {{ member.amount_won }}$
                                
                                td {{ member.draw_by?member.draw_by:'N/D' }}
                                td {{ member.pos_name?member.pos_name:'N/D' }}
                                td {{ member.amount_week }}$
                                td 
                                    div
                                        a(href="" @click.prevent="editWinnerModal(member._id)" uk-tooltip="Modifier")
                                            span(uk-icon="pencil")
                                        a(href="" @click.prevent="removeWinner(member._id)" uk-tooltip="Retirer")
                                            span(uk-icon="trash")
                
                ul.uk-pagination.uk-flex-center(uk-margin)
                    template(v-if="currentPage == 1")
                        li.uk-disabled
                            span
                                span(uk-pagination-previous)
                    template(v-else)
                        li
                            a(href="#" @click.prevent="previousPage")
                                span(uk-pagination-previous)

                    template(v-for="pageNumber in winnersCount")
                        
                        //- Active Page
                        template(v-if="currentPage==pageNumber")
                            li.uk-active
                                span
                                    span {{ pageNumber }}

                        //- Begining of pages
                        template(v-else-if="currentPage < 5")
                            template(v-if="(pageNumber > 0 &&  pageNumber < (2*5) )")
                                li
                                    a(@click="fetchWinners(pageNumber)") {{ pageNumber }}
                        //- Ending of pages
                        template(v-else-if="currentPage > (winnersCount-5)")
                            template(v-if="(pageNumber > (winnersCount-(10-1)))")
                                li
                                    a(@click="fetchWinners(pageNumber)") {{ pageNumber }}

                        //- All the other ranges
                        template(v-else)
                            template(v-if="(pageNumber > (currentPage-5))&&(pageNumber < (currentPage+5))")
                                li
                                    a(@click="fetchWinners(pageNumber)") {{ pageNumber }}

                    
                    template(v-if="currentPage >= winnersCount")
                        li.uk-disabled
                            span
                                span(uk-pagination-next)
                    template(v-else)
                        li
                            a(href="#" @click.prevent="nextPage")
                                span(uk-pagination-next)
               
               
div#modalEditWinner(uk-modal="bg-close:false; esc-close:false;")   
    div.uk-modal-dialog
        Form(ref="form" @submit="onSubmit" :validation-schema="validationSchema" v-slot="{ resetForm, meta, validate}")
            div.uk-modal-header
                h2.uk-modal-title Modifier un gagnant archivé
            div.uk-modal-body    
                SearchMember(name="member_id")
                //- SearchMember(name="member_id" disableClear)

                div.uk-width-3-5.uk-margin
                    Calendar(name="draw_date" type="winners")

                Field(name="amount_won" v-slot="{ field, errorMessage }")
                    div.uk-margin.uk-form-controls.uk-width-2-5
                        label.uk-form-label(for="form-amount-won") Montant gagné
                        div.uk-inline
                            span.uk-form-icon.uk-form-icon-flip $
                            input.uk-input#form-amount-won(type="text" v-bind="field")

                Field(name="amount_week" v-slot="{ field, errorMessage }")
                    div.uk-margin.uk-form-controls.uk-width-2-5
                        label.uk-form-label(for="form-amount-week") Montant de la semaine
                        div.uk-inline
                            span.uk-form-icon.uk-form-icon-flip $
                            input.uk-input#form-amount-week(type="text" v-bind="field")

                Field(name="member_paid" v-slot="{ field, errorMessage }")
                    div.uk-margin.uk-width-1-5
                        label.uk-form-label(for="form-member-paid") Payé?
                        div.uk-form-controls
                            select.uk-select(v-bind="field")
                                option(value="true") Oui
                                option(value="false") Non

                Field(name="draw_by" v-slot="{ field, errorMessage }")
                    div.uk-margin
                        label.uk-form-label(for="form-amount-won") Pigé par
                        div.uk-form-controls.uk-width-3-5
                            input.uk-input#form-amount-won(type="text" v-bind="field")
            
                Field(name="pos_id" v-slot="{ field, errorMessage }")
                    div.uk-margin.uk-width-3-5
                        label.uk-form-label(for="form-member-paid") Point de vente
                        div.uk-form-controls
                            select.uk-select(v-bind="field")
                                option(value="none") N/D
                                template(v-for="pos in posList")
                                    option(:value="pos._id") {{pos.pos_name}}
            div.uk-modal-footer(uk-margin)
                button.uk-button.uk-button-primary.uk-margin-right(type="submit" :disabled="!meta.valid || !meta.dirty || loading") Enregistrer
                button.uk-button.uk-button-link(@click.prevent="closeModal();" :disabled="loading") Annuler
                //- button.uk-button.uk-button-link(@click="resetForm(); closeModal();" :disabled="loading") Annuler
</template>

<style scoped>
.uk-pagination>*{
    width: 45px;
    margin: 2px;
    text-align: center;
}
</style>