<script setup>
import { onMounted, ref,unref, watch, toRef} from 'vue'
import SearchMember from '@/components/SearchMember.vue'
import axios from 'axios';

const selectedMembers = ref([]);
const groupNotes = ref('');

const newGroup = ref({members:[],notes:[]});

const memberSelected = (memberData)=>{
    console.log('memData',memberData)
    selectedMembers.value.push(memberData);

    newGroup.value.members = selectedMembers.value.map((member)=>{return member._id})
}

const saveGroup = ()=>{
    console.log('Saving group');

    console.log('newGroup.value',newGroup.value) 

    try{
        axios.post('/api/v1/groups',newGroup.value);
        newGroup.value = {members:[],notes:[]};
        selectedMembers.value = [];
    }
    catch(err){
        console.error(err);
    }
}

const groups = ref([]);

const fetchGroups = async ()=>{
    try{
        const res = await axios.get('/api/v1/groups');
        groups.value = res.data.groups;

        console.log('groups.value',groups.value)
    }
    catch(err){
        console.error(err);
    }
}

onMounted(()=>{
    fetchGroups();
})

const editGroupModal = (group_id)=>{
    alert(`Editing ${group_id}`)
}

const removeGroup = (group_id)=>{
    alert(`deleting ${group_id}`)
}
</script>

<template lang="pug">
div.uk-width-1-1
    ul.uk-breadcrumb 
        li
            span Gestion des paiements 
        li
            span Groupes
        li
            span Liste


table.uk-table.uk-table-small.uk-table-divider.uk-table-hover.uk-table-middle.uk-table-responsive
    thead
        tr
            th Groupe
            //- th
    tbody
        template(v-for="(group,index) in groups")
            tr
                td
                    b Groupe {{ (index+1) }}
                    br
                    template(v-for="member in group.member")
                        div 
                            i {{member.number}}
                            | {{member.firstname}} {{member.lastname}}
                //- td.uk-text-right 
                //-     div
                //-         a(href="" @click.prevent="editGroupModal(group._id)")
                //-             span(uk-icon="pencil")
                //-         a(href="" @click.prevent="editGroupModal(group._id)")
                //-             span(uk-icon="pencil")
                //-         a(href="" @click.prevent="removeGroup(group._id)")
                //-             span(uk-icon="trash")
                    

</template>

<style scoped>
i{
    display: inline-block;
    min-width:60px;
}
</style>