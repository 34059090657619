<script setup>
import { onMounted, ref,unref, watch, toRef} from 'vue'
import { Field, Form, ErrorMessage,configure } from 'vee-validate';
import SearchMember from '@/components/SearchMember.vue'
import axios from 'axios';
import * as yup from 'yup';
import UIkit from 'uikit';
import { DateTime } from 'luxon';
import Card_MemberInformation from '@/views/ViewMemberPaymentMgmt/Manage_MemberInformation.vue';
import Card_MemberTransactions from '@/views/ViewMemberPaymentMgmt/Manage_MemberTransactions.vue';
import Form_AddDeposit from '@/components/Form_AddDeposit.vue'

const selectedMembers = ref(null);
const selectedNote = ref(null);
const group = ref({});
const transactions = ref([]);
const form_gestion = ref();
const form2 = ref();
const form_notes = ref();

const notes = ref([]);
const formy = ref(null);
const groupMainContact = ref("");


const props = defineProps({
    member:{
        type:Object
    },
    disableLoading:{
        type:Boolean
    }
});
const loading = ref(!props.disableLoading);

watch(()=>props.member,(value)=>{
    if(!props.disableLoading)
        loading.value = true;
    memberSelected(value)
})

const memberSelected = (memberData)=>{

    console.log('memberData',memberData)
   
    if(memberData===null){
        form_gestion.value.resetForm({values:{manage:false,reminder:"none",payment_method:"other"}});
        selectedMembers.value = null;
        group.value.data = null;
        group.value._id = null;
        notes.value = null;
        transactions.value = null;
    }
    else{
        selectedMembers.value = memberData;
        fetchMemberInfo();
    }
}

const saveGroup = async (groupId)=>{
    loading.value = true;
    try{
        let data = {
            _id:groupId,
            members:group.value.data.map((mem)=>mem._id),
            primary:groupMainContact.value
        }
        await axios.put('/api/v1/groups/',data);
        UIkit.modal('#modalEditGroups',{}).hide();
    }
    catch(err){
        console.error(err);
    }
    loading.value = false;
}

const fetchMemberInfo = async ()=>{
    loading.value = true;
    try{
        let res = await axios.get(`/api/v1/members/manage/${selectedMembers.value._id}`);

        if(res.data.params){
            form_gestion.value.resetForm({values:res.data.params})
        }
        else{
            form_gestion.value.resetForm({values:{manage:false,reminder:"none",payment_method:"other"}})
        }

        form_notes.value.resetForm({values:{note:""}});
        notes.value = res.data.notes;
        transactions.value = res.data.transactions;
        group.value.data = res.data.group;
        group.value._id = res.data.group_id;
        groupMainContact.value = res.data.group_primary;
    }
    catch(err){
        console.error(err);
    }
    loading.value = false;
}

const fetchMemberGroupInfo = async ()=>{
    loading.value = true;
    try{
        let res = await axios.get(`/api/v1/members/manage/${selectedMembers.value._id}`);

        group.value.data = res.data.group;
        group.value._id = res.data.group_id;
        groupMainContact.value = res.data.group_primary;
    }
    catch(err){
        console.error(err);
    }
    loading.value = false;
}

const onSubmit = async (values)=>{
    loading.value = true
    values.member_id = selectedMembers.value._id;
    
    try{
        await axios.put('/api/v1/members/manage/parameters/',values);
        UIkit.notification("<span uk-icon='icon: check'></span> Paramètres modifiés avec succès",{status:'success',pos:'bottom-right'});
        await fetchMemberInfo();
    }
    catch(err){
        console.error(err);
        UIkit.notification("<span uk-icon='icon: close'></span> Erreure lors de la modification des paramètres",{status:'danger',pos:'bottom-right'});
    }
    loading.value = false
}
const onSubmitNote = async (values)=>{
    loading.value = true;
    values.member_id = selectedMembers.value._id;
    console.log('onSubmitNote',values)
    try{
        await axios.post('/api/v1/members/manage/notes/',values);
        UIkit.notification("<span uk-icon='icon: check'></span> Note ajouté avec succès",{status:'success',pos:'bottom-right'});
        await fetchMemberInfo();
    }
    catch(err){
        console.error(err);
        UIkit.notification("<span uk-icon='icon: close'></span> Erreure lors de l'ajout de la note",{status:'danger',pos:'bottom-right'});
    }
}

onMounted(()=>{
    form_gestion.value.resetForm({values:{manage:false,reminder:"none",payment_method:"other"}});
    form_notes.value.resetForm({values:{note:""}});
});

const schema_form = yup.object({
    "reminder":yup.string(),
    "payment_method":yup.string(),
    "manage":yup.bool().nullable()
});

const open_edit_note_modal = async (note_id)=>{

    let res;
    let member_id = selectedMembers.value._id;
    selectedNote.value = note_id;

    try{
        res = await axios.get(`/api/v1/members/manage/notes/${member_id}/${note_id}`);
        form2.value.resetForm({values:{note:res.data.note}})
        UIkit.modal('#modalEditUser',{stack:true}).show();
    }
    catch(err){
        console.error(err);
    }
}

const edit_note = async (values)=>{
    let member_id = selectedMembers.value._id;
    let note_id = selectedNote.value;

    try{
        await axios.put(`/api/v1/members/manage/notes/${member_id}/${note_id}`,values);
        await fetchMemberInfo();
        UIkit.modal('#modalEditUser').hide();
    }
    catch(err){
        console.error(err);
    }
}

const delete_note = async (note_id)=>{
    let member_id = selectedMembers.value._id;
    try{
        await UIkit.modal.confirm("Voulez-vous vraiment supprimer cette note?",{stack:true,i18n: {ok: 'Oui',cancel:'Non'}});

        try{
            await axios.delete(`/api/v1/members/manage/notes/${member_id}/${note_id}`);
            await fetchMemberInfo();
            UIkit.notification("<span uk-icon='icon: check'></span> Note supprimé avec succès",{status:'success',pos:'bottom-right'});
        }
        catch(err){
            console.error(err);
            UIkit.notification("<span uk-icon='icon: close'></span> Erreur lors de la suppression de l'utilisateur",{status:'danger',pos:'bottom-right'});
        }
    }
    catch(err){}
}

const closeForm = ()=>{
    UIkit.modal('#modalEditUser',{}).hide();
    
}
const closeForm2 = async ()=>{
    UIkit.modal('#modalEditGroups',{}).hide();
    await fetchMemberGroupInfo(); //TODO: temp fix k
}
const editGroups = ()=>{

    // Add current user to group if not present
    if( group.value.data &&  group.value.data.length==0){
        group.value.data = [
            {
                _id:selectedMembers.value._id,
                firstname: selectedMembers.value.firstname,
                lastname:selectedMembers.value.lastname,
                number:selectedMembers.value.number,
                endDate:selectedMembers.value.endDate
            }
        ];
        groupMainContact.value = selectedMembers.value._id;
    }

    UIkit.modal('#modalEditGroups',{stack:true}).show();
}



const groupEditMemberSelected = async (memberData)=>{

    if(memberData){
        
        // Check if member is already part of a group
        let res = await axios.get(`/api/v1/members/group/${memberData._id}`);

        if(res.data){
            console.log(res.data);
            alert(`Ce membre fait déja partie d'un groupe.`);
        }
        else{
            group.value.data.push({_id:memberData._id,firstname: memberData.firstname,lastname:memberData.lastname,number:memberData.number,endDate:memberData.endDate})
        }
            
        formy.value.clearInput();   
    }
}



const removeMemberFromGroup = (id)=>{

    let index = group.value.data.findIndex((element)=>element._id==id)
    if(index != -1){
        group.value.data.splice(index,1);
    }
}

const addDepositToGroup = ()=>{
    console.log('addDepositGroup',selectedGroupMembers.value)
    UIkit.modal('#addDepositToGroup_modal',{stack:true}).show();
}

const selectedGroupMembers = ref([]);

const memberDetailsModalData = ref({});

const showMemberDetails = async (memberId)=>{

    try{
        let res = await axios.get(`/api/v1/members/${memberId}`);
        memberDetailsModalData.value = res.data;

        console.log('memberDetailsModalData',res)
        console.log('memberDetailsModalData',memberDetailsModalData.value)

        UIkit.modal('#memberDetailsModal',{stack:true}).show();
    }
    catch(err){
    
    }    
}


const reloadData = async ()=>{
    await fetchMemberInfo();
}
</script>

<template lang="pug">
div.uk-width-1-1(uk-grid)  
    
    Card_MemberInformation(:member="selectedMembers")

    div(class="uk-width-1-1 ")
        div.uk-card.uk-card-default.uk-card-body(:class="{'dd-disabled-card': selectedMembers==null}")
            div.uk-overlay-primary.uk-position-cover(v-if="loading" style="z-index:1000;")
                div.uk-position-center
                    span(uk-spinner="ratio:2")
            Form(ref="form_gestion" @submit="onSubmit" :validation-schema="schema_form" v-slot="{ resetForm, meta, validate}")
                h4.uk-heading-line.uk-text-center 
                    span Paramètres
                div.uk-margin
                    Field.uk-checkbox.uk-margin-small-right(name="manage" type="checkbox" :value="true" :unchecked-value="false" v-slot="field" :disabled="selectedMembers==null")
                    label.uk-form-label
                        | Suvire ce membre
                        span(uk-tooltip="Ajouter à la liste des membres gérés.")
                            span.uk-margin-small-left.dd-help-btn(uk-icon="question")
 
                div.uk-margin
                    label.uk-form-label 
                        | Rappel avant échéance
                        span(uk-tooltip="Nombre de semaines d'avance avant de rappeler le membre.")
                            span.uk-margin-small-left.dd-help-btn(uk-icon="icon:question" )
                    div.uk-form-controls
                        Field(name="reminder" v-slot="{ field, errorMessage }")
                            select.uk-select.uk-form-small(:disabled="selectedMembers==null" v-bind="field")
                                option(value="none") Aucun Rappel
                                option(value="1week") 1 Semaine
                                option(value="2weeks") 2 Semaines
                                option(value="3weeks") 3 Semaines
                                option(value="4weeks") 4 Semaines
                
                label.uk-form-label Type de paiement
                    div.uk-form-controls
                        Field(name="payment_method" v-slot="{ field, errorMessage }")
                            select.uk-select.uk-form-small(:disabled="selectedMembers==null" v-bind="field")
                                option(value="other") Autre
                                option(value="cash") Argent
                                option(value="interact") Interac
                                option(value="accesd") Virement Bancaire     
                                option(value="check") Chèque

                div.uk-margin(uk-margin)
                    button.uk-button.uk-button-primary.uk-button-small.uk-margin-right(type="submit" :disabled="selectedMembers==null || !meta.valid || !meta.dirty || loading") Enregistrer
                    button.uk-button.uk-button-link.uk-button-small(type="button" @click="resetForm" :disabled="selectedMembers==null || !meta.dirty || loading") Annuler
           
    div(class="uk-width-1-1 ")
        div.uk-card.uk-card-default.uk-card-body(:class="{'dd-disabled-card': selectedMembers==null}")
            div.uk-overlay-primary.uk-position-cover(v-if="loading" style="z-index:1000;")
                div.uk-position-center
                    span(uk-spinner="ratio:2")
            h4.uk-heading-line.uk-text-center 
                span Notes

            div.uk-margin
               
                table.dd-notes-table.uk-table.uk-table-small.uk-table-divider.uk-table-hover.uk-table-middle.uk-table-responsive
                    tbody
                        template(v-if="!(notes && notes.length)")
                            tr
                                td Aucune note
                        template(v-else)
                            template(v-for="note in notes")
                                tr
                                    td
                                        span {{note.note}}
                                        a.uk-icon-link.dd-edit-note(@click="open_edit_note_modal(note._id)" uk-icon="pencil" uk-tooltip="Modifier")
                                        a.uk-icon-link.dd-delete-note(@click="delete_note(note._id)" uk-icon="trash" uk-tooltip="Supprimer")
                    
                Form(ref="form_notes" @submit="onSubmitNote" v-slot="{ resetForm, meta, validate}")
                    Field(name="note" v-slot="{ field, errorMessage }")
                        textarea.uk-textarea(:disabled="selectedMembers==null" v-bind="field")
                    div.uk-margin(uk-margin)
                        button.uk-button.uk-button-primary.uk-button-small.uk-margin-right(type="submit" :disabled="selectedMembers==null || !meta.valid || !meta.dirty || loading") Ajouter
                        button.uk-button.uk-button-link.uk-button-small(@click="resetForm" :disabled="selectedMembers==null || !meta.dirty || loading") Annuler
                          
    div(class="uk-width-1-1")
        div.uk-card.uk-card-default.uk-card-body(:class="{'dd-disabled-card': selectedMembers==null}")  
            div.uk-overlay-primary.uk-position-cover(v-if="loading" style="z-index:1000;")
                div.uk-position-center
                    span(uk-spinner="ratio:2")      
            h4.uk-heading-line.uk-text-center 
                span Groupe
            div
                template(v-if="group.data && group.data.length")
                    table.uk-table.uk-table-divider.uk-table-hover.uk-table-small
                        tr
                            th
                            th
                            th Numéro
                            th.uk-table-expand Nom
                            th Payé jusqu'au
                            th
                           
                        template(v-for="member in group.data")
                            tr
                                td 
                                    input.uk-checkbox(type="checkbox" :value="member._id" v-model="selectedGroupMembers")
                                td 
                                    a(@click="showMemberDetails(member._id)") Détails
                                td 
                                    b {{ member.number }}
                                td {{ member.firstname }} {{ member.lastname }}
                                td {{ member.endDate }}
                                td
                                    template(v-if="member._id==groupMainContact")
                                        span.uk-label.uk-label-success Contact Principal

                template(v-else)
                    | Ne fait pas partie d'un groupe
            div(uk-grid)
                div.uk-width-1-2
                    button.uk-button.uk-button-primary.uk-button-small(type="button" @click="addDepositToGroup()" :disabled="selectedMembers==null || selectedGroupMembers.length ==0") 
                        span(uk-icon="plus-circle")
                        | &nbsp; Ajouter un paiement
                div.uk-width-1-2.uk-text-right
                    button.uk-button.uk-button-primary.uk-button-small.uk-margin-right(type="button" @click="editGroups()" :disabled="selectedMembers==null") 
                        template(v-if="group.data && group.data.length")
                            span(uk-icon="pencil")
                            |  &nbsp; Modifier le groupe
                        template(v-else)
                            span(uk-icon="plus-circle")
                            | &nbsp; Créer un groupe
    
    Card_MemberTransactions(:transactions="transactions" :selectedMember="selectedMembers" :loading="loading" @reloadData="reloadData()")
                                    

div#modalEditGroups.uk-modal-container(uk-modal="bg-close:false;esc-close:false;")
    div.uk-modal-dialog
        div.uk-modal-header
            h2.uk-modal-title Modifier un groupe
        div.uk-modal-body(uk-overflow-auto)
            div(class="uk-width-2-5")
                SearchMember(ref="formy" @searchResults="groupEditMemberSelected" label="Ajouter un membre")
            table.uk-table.uk-table-divider.uk-table-hover.uk-table-small
                tr
                    th Numéro
                    th.uk-table-expand Nom
                    th.uk-text-nowrap Contact Principal
                    th
                template(v-if="group && group.data && group.data.length")
                    template(v-for="member in group.data")
                        tr
                            td.uk-table-shrink
                                b {{ member.number }}
                            td {{ member.firstname }} {{ member.lastname }}
                            td.uk-table-shrink.uk-text-center
                                div.uk-preserve-width
                                    input.uk-radio(type="radio" :value="member._id" v-model="groupMainContact" name="mainContact")
                            td.uk-table-shrink
                                div.uk-preserve-width
                                    a.uk-icon-link(@click="removeMemberFromGroup(member._id)" uk-icon="minus-circle" uk-tooltip="Retirer")
                template(v-else)
                    tr
                        td.uk-text-center(colspan="4") Aucun membre dans le groupe
        div.uk-modal-footer(uk-margin)
            button.uk-button.uk-button-primary.uk-margin-right(type="button" @click="saveGroup(group._id)" :disabled="loading")
                | Enregistrer
            button.uk-button.uk-button-link(type="button" @click="closeForm2" :disabled="loading")
                | Annuler



div#modalEditUser.uk-modal-container(uk-modal="bg-close:false;esc-close:false;")
    div.uk-modal-dialog
        Form( @submit="edit_note"  ref="form2" v-slot="{ resetForm, meta, validate, errors }")
            
            div.uk-modal-header
                h2.uk-modal-title Modifier une note
            div.uk-modal-body(uk-overflow-auto)
                Field(name="note" v-slot="{ field, errorMessage }")
                    div.uk-margin.uk-width-2-5
                        label.uk-form-label Note
                        div.uk-form-controls
                            textarea.uk-textarea(type="text" placeholder="" :class="{'uk-form-danger': errorMessage}" v-bind="field")
                
            
                
            div.uk-modal-footer(uk-margin)
                button.uk-button.uk-button-primary.uk-margin-right(type="submit" :disabled="!meta.valid || !meta.dirty || loading")
                    | Enregistrer
                button.uk-button.uk-button-link(type="button" @click="closeForm" :disabled="loading")
                    | Annuler

div#addDepositToGroup_modal.uk-modal-container(uk-modal="bg-close:false;esc-close:false;")
    div.uk-modal-dialog.uk-modal-body
        button.uk-modal-close-default(type="button" uk-close)
        Form_AddDeposit(:selectedd="selectedGroupMembers")

div#memberDetailsModal.uk-modal-container(uk-modal="bg-close:false;esc-close:false;")
    button.uk-modal-close-default(type="button" uk-close)
    div.uk-modal-dialog
        div.uk-modal-header
            h2.uk-modal-title {{ memberDetailsModalData.firstname }} {{ memberDetailsModalData.lastname }}
        div.uk-modal-body
            Card_MemberInformation(:member="memberDetailsModalData")

</template>

<style scoped>
.dd-disabled-card{
    opacity:0.5;
}
.dd-notes-table td{
    position:relative;
    padding-right:25px;
    height:36px;
}
.dd-edit-note{
    position:absolute;
    right: 3px;
    top: 3px;
}
.dd-delete-note{
    position:absolute;
    right: 3px;
    top: 28px;
}

.dd-help-btn{
    position: relative;
    top: -4px;
    zoom: 0.8;
}
</style>