<script setup>
import { onMounted, ref,unref, watch} from 'vue'
import axios from 'axios';
import { Field, Form, ErrorMessage,configure } from 'vee-validate';
import * as yup from 'yup';
import UIkit from 'uikit'; 

const defaultValues = {
    "pos_name":""
}

const form_error = ref("");

//TODO use common schema with backend
const validationSchema = yup.object({
    "pos_name":yup.string().required().min(2).trim().matches(/^[\p{Letter}\s]+$/ui,"Le nom du point de vente doit contenir des charactères alpha-numérique seulement."),
});

const onSubmit = async (values,{resetForm})=>{
    try{
        const res = await axios.post(`/api/v1/pointofsales/`,values);
        resetForm();
        form_error.value = '';
        UIkit.notification("<span uk-icon='icon: check'></span> Point de vente ajouté avec succès",{status:'success',pos:'bottom-right'});
   
    }
    catch(err){
        console.error(err);
        form_error.value = err.message;
        UIkit.notification("<span uk-icon='icon: close'></span> Erreure lors de l'ajout du point de vente",{status:'danger',pos:'bottom-right'});
    }
}
</script>


<template lang="pug">
Form(@submit="onSubmit" :validation-schema="validationSchema" v-slot="{ resetForm, meta, validate}" :initial-values="defaultValues")
    
    Field(name="pos_name" v-slot="{ field, errorMessage }")
        div.uk-margin.uk-width-2-5
            label.uk-form-label Nom du point de vente *
            div.uk-form-controls
                input.uk-input(type="text" placeholder="" :class="{'uk-form-danger': errorMessage}" v-bind="field")    
    
    p(uk-margin)
        button.uk-button.uk-button-primary.uk-margin-right(type="submit" :disabled="!meta.valid")
            | Ajouter
        button.uk-button.uk-button-link(type="button" @click="resetForm" :disabled="!meta.dirty")
            | Annuler
        div.uk-alert-danger(uk-alert v-if="form_error.length")
            a.uk-alert-close(uk-close)
            | {{ form_error }}
</template>