<script setup>

const props = defineProps({
    member: {
        type: Object,
    }
});

const formatTelephone = (telephone)=>{
    
    let ret = telephone;

    if(telephone){
    
        const areaCode = telephone.substring(0,3);
        const middle = telephone.substring(3,6);
        const last = telephone.substring(6,10);
        
        if(telephone.length > 6){
            ret = `(${areaCode}) ${middle}-${last}`;
        }
        else if(telephone.length > 3){
            ret = `(${areaCode}) ${middle}`; 
        }
        else if(telephone.length > 0){
            ret = `(${areaCode}`;
        }
    }
    return ret;
}
</script>

<template lang="pug">
div(class="uk-width-1-1")
    div.uk-card.uk-card-default.uk-card-body(:class="{'dd-disabled-card': props.member==null}")
        h4.uk-heading-line.uk-text-center 
            span Information
        div.uk-margin
            table.uk-table.uk-table-small.dd-table
                tr
                    td.uk-text-nowrap
                        b Nom
                    td(v-if="props.member!=null") {{ props.member.firstname }} {{ props.member.lastname }}
                tr
                    td.uk-text-nowrap
                        b Numéro
                    td(v-if="props.member!=null") {{ props.member.number }}
                tr
                    td.uk-text-nowrap
                        b Payé jusqu`au
                    td(v-if="props.member!=null") {{ props.member.endDate }}
                tr
                    td.uk-table-shrink.uk-text-nowrap
                        b Membre depuis
                    td(v-if="props.member!=null") {{ props.member.creationDate }}  
                tr
                    td.uk-table-shrink.uk-text-nowrap
                        b Ville
                    td(v-if="props.member!=null") {{ props.member.city?props.member.city:'N/D' }}
                tr
                    td.uk-table-shrink.uk-text-nowrap
                        b Addresse
                    td(v-if="props.member!=null") {{ props.member.address?props.member.address:'N/D' }}          
                tr
                    td.uk-table-shrink.uk-text-nowrap
                        b Code Postal
                    td(v-if="props.member!=null") {{ props.member.postal_code?props.member.postal_code:'N/D' }}  
                tr
                    td.uk-table-shrink.uk-text-nowrap
                        b Telephone
                    td(v-if="props.member!=null") 
                        | {{ props.member.telephone1?formatTelephone(props.member.telephone1):'N/D' }}  
                        br          
                        | {{ formatTelephone(props.member.telephone2) }}  
                tr
                    td.uk-table-shrink.uk-text-nowrap
                        b Courriel
                    td(v-if="props.member!=null") {{ props.member.email?props.member.email:'N/D' }} 
</template>

<style scoped>
.dd-table td{
    padding:2px 12px;
}
</style>