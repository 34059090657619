<script setup>
import { onMounted, ref,unref, watch, toRef} from 'vue'
import SearchMember from '@/components/SearchMember.vue'
import axios from 'axios';

const selectedMembers = ref([]);
const groupNotes = ref('');

const newGroup = ref({members:[],notes:[]});

const memberSelected = (memberData)=>{
    console.log('memData',memberData)
    if(memberData){
        selectedMembers.value.push(memberData);
        newGroup.value.members = selectedMembers.value.map((member)=>{return member._id})
    }

}

const saveGroup = ()=>{
    console.log('Saving group');

    console.log('newGroup.value',newGroup.value) 

    try{
        axios.post('/api/v1/groups',newGroup.value);
        newGroup.value = {members:[],notes:[]};
        selectedMembers.value = [];
    }
    catch(err){
        console.error(err);
    }
}

const groups = ref([]);


</script>

<template lang="pug">
div.uk-width-1-1
    ul.uk-breadcrumb 
        li
            span Gestion des paiements 
        li
            span Groupes
        li
            span Ajouter

SearchMember(@searchResults="memberSelected")

table.uk-table.uk-table-divider.uk-table-hover.uk-table-small
    thead
        tr 
            th Numéro de Membre
            th Nom
            th
    tbody
        template(v-if="selectedMembers.length")
            template(v-for="member in selectedMembers")
                tr
                    td {{ member.number }}
                    td {{ member.firstname }} {{ member.lastname }}
                    td 
                        span(uk-icon="pencil") 
                        span(uk-icon="trash") 
        template(v-else)
            tr
                td.uk-text-center(colspan="3") Aucun Membre Sélectionné


div.uk-margin
    label.uk-form-label Notes du groupe
    textarea.uk-textarea(v-model="groupNotes") 
p Un membre ne peux appartenir qu'a un seul groupe.
button.uk-button.uk-button-primary(@click.prevent="saveGroup()") Sauvegarder le Groupe

</template>