<script setup>
import { onMounted, ref, watch, computed } from 'vue'
import { Field, Form } from 'vee-validate';
import { useUsersStore } from '@/stores/user.js';
import UIkit from 'uikit';
import axios from 'axios';
import { schema_add_transaction_as_user,schema_add_transaction_as_pointofsale } from '@/../../validation_schemas/members.mjs'
import { DateTime } from 'luxon';
import config from '@/../../../../config_public.mjs';

// Components
import Keypad from '@/components/Keypad.vue'
import SearchMember from '@/components/SearchMember.vue'
import Receipt from '@/components/Receipt.vue'
import Calendar from '@/components/Calendar.vue'
    

const defaultValues = {
    "member_id":"",
    "automatic_invoice_number":false,
    "amount":"",
    "pos_id":"",
    "date":"",
    "payment_method":"cash",
    "invoice_number":""
}

const form                      = ref('form');
const userStore                 = useUsersStore();
const form_error                = ref("");
const pointOfSales              = ref([]);
const memberStatus              = ref({});
const automatic_invoice_number  = ref(false);
const payment_method            = ref(defaultValues.payment_method);
const loading                   = ref(true);
const loading2                  = ref(false);

const confirm_data = ref({
    firstname:'',
    lastname:'',
    amount:0,
    from:'',
    to:'',
})

const props = defineProps({
    selectedd:{
        type:[String,Array],
        default:''
    }
});



watch(()=>props.selectedd,(newval)=>{
    defaultValues.member_id = newval.join(',');
    form.value.resetForm({values:defaultValues})
})


const initSelected = computed(()=>{
    console.log(props.selectedd.join(','))
    return props.selectedd.join(',')
})

// TODO this should be a component and be used in modify deposit
const get_payment_method_color = ()=>{
    
    let ret = {};
    switch(payment_method.value){
        case 'cash':
            ret = {dd_payment_method_color_green:true}
            break;
        case 'interact':
            ret = {dd_payment_method_color_blue:true}
            break;
        case 'accesd':
            ret = {dd_payment_method_color_purple:true}
            break;
        case 'check':
            ret = {dd_payment_method_color_orange:true}
            break;
    }

    console.log('get_payment_method_color',ret)
    return ret;
}

// TODO this should be in a common utils module
const paymentMethods = ref([
    {value:'cash',display:'Argent'},
    {value:'interact',display:'Interac'},
    {value:'accesd',display:'Virement Bancaire'},
    {value:'check',display:'Chèque'},
])

onMounted(async ()=>{

    try{
        const response = await axios.get(`/api/v1/pointofsales`);        
        pointOfSales.value = response.data;
    }
    catch(err){
        console.error(err);
    }
    loading.value = false;
})


const onSubmit = async (values,{resetForm})=>{
    loading.value = true;
    try{
        await custom_modal(values);
    }
    catch(err){
        loading.value = false;
        return;
    }
    
    try{
        const res = await axios.post(`/api/v1/transactions/`,values);
        memberStatus.value = res.data;

        resetForm({
            values:{
                member_id:"",
                automatic_invoice_number:false,
                amount:"",
                pos_id:values.pos_id,
                date:values.date,
                payment_method:'cash',
                invoice_number:""
        }});

        UIkit.notification("<span uk-icon='icon: check'></span> Dépot fait avec succès",{status:'success',pos:'bottom-right'});
        UIkit.modal('#receipt_modal').show();
    }
    catch(err){
        console.error(err);
        form_error.value = "error"
        UIkit.notification("<span uk-icon='icon: close'></span> Erreure lors du dépot",{status:'danger',pos:'bottom-right'});
    }
    loading.value = false;
    loading2.value = false;
}

let mod_resolve;
let mod_reject;

const custom_modal = async (values)=>{

    const res = await axios.post(`/api/v1/transactions/?simulate=true`,values);
    
    confirm_data.value.number = res.data.number,
    confirm_data.value.telephone1 = formatTelephone(res.data.telephone1),
    confirm_data.value.telephone2 = formatTelephone(res.data.telephone2),
    confirm_data.value.firstname = res.data.firstname,
    confirm_data.value.lastname = res.data.lastname,
    confirm_data.value.amount = res.data.amount,
    confirm_data.value.from = DateTime.fromISO(res.data.current_endDate).setLocale(config.general.locale).toFormat(config.general.formats.end_date_view_alt),
    confirm_data.value.to = DateTime.fromISO(res.data.new_endDate).setLocale(config.general.locale).toFormat(config.general.formats.end_date_view_alt),
    
    
    UIkit.modal('#confirm_modal').show();
    
    return new Promise((resolve,reject)=>{
        mod_resolve = ()=>{
            UIkit.modal('#confirm_modal').hide();
            resolve();            
        };
        mod_reject = ()=>{
            UIkit.modal('#confirm_modal').hide();
            reject();
        };
    })
}


const btn_ok = ()=>{
    loading.value = true;
    loading2.value = true;
    mod_resolve();
}

const btn_cancel = ()=>{
    mod_reject();
    loading.value = false;
}


// TODO this should be in utilities module
const formatTelephone = (telephone)=>{
    
    let ret = telephone;
    
    const areaCode = telephone.substring(0,3);
	const middle = telephone.substring(3,6);
	const last = telephone.substring(6,10);
    
	if(telephone.length > 6){
        ret = `(${areaCode}) ${middle}-${last}`;
    }
	else if(telephone.length > 3){
        ret = `(${areaCode}) ${middle}`; 
    }
	else if(telephone.length > 0){
        ret = `(${areaCode}`;
    }
    return ret;
}
</script>

<template lang="pug">
div(class="uk-width-1-1")
    template(v-if="userStore.isUser()")
        
        Form(ref="form" @submit="onSubmit" :validation-schema="schema_add_transaction_as_user" v-slot="{values,errors, resetForm, meta, validate }" :initial-values="defaultValues")
            div(uk-grid)
                div.uk-width-1-1(v-if="props.selectedd")
                    div.uk-card.uk-card-default.uk-card-body
                        | {{ props.selectedd.length }} membres séléctionnés
                        Field(name="member_id" v-slot="{ field, errorMessage }" :value="initSelected")
                            input(type="hidden" v-bind="field")
                div.uk-width-1-1
                    div.uk-card.uk-card-default.uk-card-body
                        div.uk-width-2-5.uk-margin
                            SearchMember(name="member_id")
                div.uk-width-1-1
                    div.uk-card.uk-card-default.uk-card-body
                        Keypad(name="amount")

                        Field(name="pos_id" v-slot="{ field, errorMessage }")
                            div.uk-margin.uk-width-2-5
                                label.uk-form-label Point de vente
                                select.uk-select(v-bind="field")
                                    template(v-for="pos in pointOfSales")
                                        option(:value="pos._id") {{ pos.pos_name}} 

                        div.uk-width-2-5
                            Calendar(name="date" type="da")
                        
                        Field(name="payment_method" v-slot="{ field, errorMessage }"  v-model="payment_method")
                            div.uk-margin.uk-width-2-5
                                label.uk-form-label Méthode de paiement
                                select.uk-select(v-bind="field" :class="get_payment_method_color()")
                                    template(v-for="method in paymentMethods")
                                        option(:value="method.value") {{ method.display }} 
                        div.uk-margin
                            div(uk-grid)
                                div.uk-width-1-5
                                    Field(name="invoice_number" v-slot="{ field, errorMessage }")
                                        div.uk-margin
                                            label.uk-form-label Numéro de facture
                                            input.uk-input(type="text" v-bind="field" :disabled="automatic_invoice_number==true" :readonly="automatic_invoice_number")
                                div.uk-width-3-5.uk-flex.uk-flex-bottom
                                    div.uk-flex.uk-flex-column
                                        div
                                            Field.uk-checkbox(name="automatic_invoice_number" type="checkbox" :value="true" :unchecked-value="false" v-slot="field" v-model="automatic_invoice_number")
                                            | &nbsp;Automatique
                                        div.uk-text-muted.uk-text-small Un numéro de facture sera généré automatiquement par le système.
                        hr
                        div.uk-width-1-1
                            button.uk-button.uk-button-primary.uk-width-1-2(type="submit" :disabled="!meta.valid || loading") 
                                template(v-if="loading")
                                    div(uk-spinner)
                                template(v-else)
                                    | Enregistrer
                            button.uk-button.uk-button-link.uk-width-1-2(@click="resetForm" :disabled="!meta.dirty || loading") Annuler
                           
    
    template(v-else)
        Form(@submit="onSubmit" :validation-schema="schema_add_transaction_as_pointofsale" v-slot="{ resetForm, meta, validate }" :initial-values="defaultValues")
            div(uk-grid)
                div.uk-width-1-1
                    div.uk-card.uk-card-default.uk-card-body
                        div.uk-width-2-5.uk-margin
                            SearchMember(name="member_id")
                div.uk-width-1-1
                    div.uk-card.uk-card-default.uk-card-body
                        Keypad(name="amount")
                        hr
                        div.uk-width-1-1
                            button.uk-button.uk-button-primary.uk-width-1-2(type="submit" :disabled="!meta.valid || loading") 
                                template(v-if="loading")
                                    div(uk-spinner)
                                template(v-else)
                                    | Enregistrer
                            button.uk-button.uk-button-link.uk-width-1-2(@click="resetForm" :disabled="!meta.dirty || loading") Annuler


div#confirm_modal(uk-modal="esc-close:false;bg-close:false;")
    div.uk-modal-dialog
        div.uk-modal-header
            h2.uk-modal-title Confirmation
        div.uk-modal-body
            div.uk-text-center(uk-grid)  
                div.uk-width-1-1
                    h4 Numéro: 
                        b {{  confirm_data.number }}
                div.uk-width-1-1
                    h3
                        b(style="font-size:2em;") {{ confirm_data.firstname }} {{ confirm_data.lastname }}
                div.uk-width-1-1
                    h4 
                        | {{  confirm_data.telephone1 }}
                        br
                        | {{  confirm_data.telephone2 }}
                div.uk-width-1-1 
                    h4 Paiement de 
                        b(style="font-size:1.5em;") {{ confirm_data.amount }}$
                div.uk-width-1-1
                    h4 
                        | Pour les tirages du 
                        br
                        b(style="font-size:1.5em;") {{ confirm_data.from }}
                        br
                        | au 
                        br
                        b(style="font-size:1.5em;") {{ confirm_data.to }}
        div.uk-modal-footer
            div.uk-grid-collapse.uk-width-1-1(uk-grid)
                div.uk-width-1-1
                    button.uk-button.uk-button-primary.uk-width-1-2(@click="btn_ok()" :disabled="loading2") 
                        template(v-if="loading2")
                            div(uk-spinner)
                        template(v-else)
                            | Confirmer
                    button.uk-button.uk-button-link.uk-width-1-2(@click="btn_cancel()" :disabled="loading2")  Annuler


div#receipt_modal(uk-modal="bg-close:false;esc-close:false;")
    div.uk-modal-dialog.uk-modal-body
        button.uk-modal-close-default(type="button" uk-close)
       
        template(v-if="Array.isArray(memberStatus)")
            template(v-for="member in memberStatus")
                Receipt(:memberStatus="member")
        template(v-else)
            Receipt(:memberStatus="memberStatus")
</template>

<style>
.dd_payment_method_color_orange, 
.dd_payment_method_color_orange option, 
.dd_payment_method_color_orange:focus
{
    background-color: rgba(255, 123, 0, 0.664) !important;
    color:#fff !important;
}

.dd_payment_method_color_green, 
.dd_payment_method_color_green option, 
.dd_payment_method_color_green:focus
{
    background-color: rgba(0, 128, 0, 0.5) !important;
    color:#fff !important;
}

.dd_payment_method_color_blue, 
.dd_payment_method_color_blue option, 
.dd_payment_method_color_blue:focus
{
    background-color: rgba(0, 119, 255, 0.5) !important;
    color:#fff !important;
}

.dd_payment_method_color_purple, 
.dd_payment_method_color_purple option, 
.dd_payment_method_color_purple:focus
{
    background-color: rgba(128, 0, 128, 0.5) !important;
    color:#fff !important;
}
</style>