<script setup>
import { onMounted, ref,unref, watch, toRef} from 'vue'
import { Field, Form, ErrorMessage,configure } from 'vee-validate';
import axios from 'axios';
import * as yup from 'yup';
import Keypad from '@/components/Keypad.vue'
import SearchMember from '@/components/SearchMember.vue'
import {DateTime} from 'luxon';
import UIkit from 'uikit';
import Receipt from '@/components/Receipt.vue'
import Calendar from '@/components/Calendar.vue'
import { schema_edit_transaction_as_user, schema_edit_transaction_as_pointofsale } from '@/../../validation_schemas/members.mjs';
import {useUsersStore} from '@/stores/user.js';

const userStore     = useUsersStore();
const loading = ref(false);

const memberStatus  = ref({});

const defaultValues = {
    transaction_id:'',
    amount:'',
    pos_id:'',
    date:'',
    payment_method:"cash"
}

const payment_method = ref(defaultValues.payment_method);

const paymentMethods = ref([
    {value:'cash',display:'Argent'},
    {value:'interact',display:'Interac'},
    {value:'accesd',display:'Virement Bancaire'},
    {value:'check',display:'Chèque'},
])

const pointOfSales = ref([]);

onMounted(async ()=>{
    try{
        const response = await axios.get(`/api/v1/pointofsales`);        
        pointOfSales.value = response.data;
    }
    catch(err){
        console.error(err);
    }
})


const onSubmit = async (values,{resetForm})=>{
    loading.value = true;
    try{
        const res = await axios.put(`/api/v1/transactions/`,values);
        memberStatus.value = res.data;
        resetForm();
        UIkit.notification("<span uk-icon='icon: check'></span> Dépot modifié avec succès",{status:'success',pos:'bottom-right'});
        memberTransactions.value = [];
        form.value.resetForm({values:defaultValues})
        UIkit.modal('#receipt_edit_modal').show();
    }
    catch(err){
        console.error(err);
        // form_error.value = "error"
        UIkit.notification("<span uk-icon='icon: close'></span> Erreure lors de la modification du dépot",{status:'danger',pos:'bottom-right'});
    }
    loading.value = false;
}

const memberIdd = ref('');

watch(memberIdd,(new_value)=>{
    console.log('Loading transactions for user',new_value)
});

const memberTransactions = ref([]);
const memberTransaction_selected = ref('');
const form = ref();

watch(memberTransaction_selected,async (new_value)=>{
    console.log('new_value',new_value)

    if(new_value){
        let trans = memberTransactions.value.find((transaction)=>{
            if(transaction._id == memberTransaction_selected.value){
                return true;
            }
            return false;
        })

        console.log('Found Trans: ',trans)
        console.log('fv',form.value)
        let dd = DateTime.fromISO(trans.date).toJSDate();
        // let dd = DateTime.fromISO(trans.date).toLocaleString(DateTime.DATE_SHORT);
        console.log('dd',dd)
        form.value.resetForm({values:{
            transaction_id:trans._id,
            amount:trans.amount,
            pos_id:trans.pos_id,
            date:dd,
            payment_method:trans.payment_method
        }})
    }
})
const selectedMember = ref(null);

const memberDataChanged = async (data)=>{

    selectedMember.value = data;

    if(data === null){
        memberTransactions.value = [];
        form.value.resetForm({values:defaultValues})
    }
    else{
        console.log('memeber data changed',data);
        console.log('Loading transactions for user',data._id)
        try{
            const res = await axios.get(`/api/v1/transactions/${data._id}`);
            memberTransactions.value = res.data;
            console.log('memberTransactions',memberTransactions)
        }
        catch(err){
            console.error(err);
            // form_error.value = "error"
        }
    }
   
}

const clearForm = ()=>{
    form.value.resetForm({values:{
        transaction_id:'',
        amount:'',
        pos_id:'',
        date:''
    }});
    memberTransaction_selected.value == null;
}


// TODO this should be a component and be used in modify deposit
const get_payment_method_color = ()=>{
    
    let ret = {};
    switch(payment_method.value){
        case 'cash':
            ret = {dd_payment_method_color_green:true}
            break;
        case 'interact':
            ret = {dd_payment_method_color_blue:true}
            break;
        case 'accesd':
            ret = {dd_payment_method_color_purple:true}
            break;
        case 'check':
            ret = {dd_payment_method_color_orange:true}
            break;
    }

    return ret;
}
</script>

<template lang="pug">
div.uk-width-1-1
    ul.uk-breadcrumb 
        li
            span Paiements 
        li
            span Modifier

div(uk-grid)
    div.uk-width-1-1
        div.uk-card.uk-card-default.uk-card-body
            div.uk-width-2-5.uk-margin
                SearchMember(name="member_id" @searchResults="memberDataChanged")
    template(v-if="userStore.isUser()")
        div.uk-width-1-1(:class="{'dd-disabled-card': selectedMember==null}")
            div.uk-card.uk-card-default.uk-card-body
                Form(ref="form" @submit="onSubmit" :validation-schema="schema_edit_transaction_as_user" v-slot="{ resetForm, meta, validate }")

                    div(class="uk-width-1-1")       

                        Field(name="transaction_id" v-slot="{ field, errorMessage }" v-model="memberTransaction_selected")
                            div.uk-width-2-5
                                label.uk-form-label Transaction à modifier
                                select.uk-select(v-bind="field" :disabled="selectedMember==null")
                                    template(v-for="transaction in memberTransactions")
                                        option(:value="transaction._id") 
                                            | {{DateTime.fromISO(transaction.date).toLocaleString(DateTime.DATETIME_SHORT)}} - {{transaction.amount}} $ - {{transaction.pos_name}} - {{ transaction.invoiceNumber }}
                        
                        hr

                        Keypad(name="amount" :disabled="selectedMember==null")

                        Field(name="pos_id" v-slot="{ field, errorMessage }")
                            div.uk-margin.uk-width-2-5
                                label.uk-form-label Point de vente
                                select.uk-select(v-bind="field" :disabled="selectedMember==null")
                                    template(v-for="pos in pointOfSales")
                                        option(:value="pos._id") {{ pos.pos_name}} 
                        
                        
                        div.uk-width-2-5.uk-margin
                            Calendar(name="date" :disabled="selectedMember==null")
                        
                        Field(name="payment_method" v-slot="{ field, errorMessage }" v-model="payment_method" )
                            div.uk-margin.uk-width-2-5
                                label.uk-form-label Méthode de paiement
                                select.uk-select(v-bind="field" :class="get_payment_method_color()")
                                    template(v-for="method in paymentMethods")
                                        option(:value="method.value") {{ method.display }} 
            

                    div.uk-width-1-1
                        button.uk-button.uk-button-primary.uk-width-1-2(type="submit" :disabled="!meta.valid || !meta.dirty || loading")
                            template(v-if="loading")
                                div(uk-spinner)
                            template(v-else)
                                | Enregistrer
                        button.uk-button.uk-button-link.uk-width-1-2(@click.prevent="clearForm" :disabled="!meta.dirty || loading") Annuler

    template(v-else)
        Form(ref="form" @submit="onSubmit" :validation-schema="schema_edit_transaction_as_pointofsale" v-slot="{ resetForm, meta, validate }")
            div(class="uk-width-1-1")       

                Field(name="transaction_id" v-slot="{ field, errorMessage }" v-model="memberTransaction_selected")
                    div.uk-width-2-5
                        label.uk-form-label Transaction à modifier
                        select.uk-select(v-bind="field" :disabled="selectedMember==null")
                            template(v-for="transaction in memberTransactions")
                                option(:value="transaction._id") 
                                    | {{DateTime.fromISO(transaction.date).toLocaleString(DateTime.DATETIME_SHORT)}} - {{transaction.amount}} $ - {{transaction.pos_name}} - {{ transaction.invoiceNumber }}
                
                hr

                Keypad(name="amount" :disabled="selectedMember==null")   

            div.uk-width-1-1
                button.uk-button.uk-button-primary.uk-width-1-2(type="submit" :disabled="!meta.valid || !meta.dirty || loading")
                    template(v-if="loading")
                        div(uk-spinner)
                    template(v-else)
                        | Enregistrer
                button.uk-button.uk-button-link.uk-width-1-2(@click.prevent="clearForm" :disabled="!meta.dirty || loading") Annuler

div#receipt_edit_modal(uk-modal="bg-close:false;esc-close:false;")
    div.uk-modal-dialog.uk-modal-body
        button.uk-modal-close-default(type="button" uk-close)
        template(v-if="Array.isArray(memberStatus)")
            template(v-for="member in memberStatus")
                Receipt(:memberStatus="member")
        template(v-else)
            Receipt(:memberStatus="memberStatus")
</template>

<style scoped>
.dd-disabled-card{
    opacity:0.5;
}

</style>

<style>
.dd_payment_method_color_orange, 
.dd_payment_method_color_orange option, 
.dd_payment_method_color_orange:focus
{
    background-color: rgba(255, 123, 0, 0.664) !important;
    color:#fff !important;
}

.dd_payment_method_color_green, 
.dd_payment_method_color_green option, 
.dd_payment_method_color_green:focus
{
    background-color: rgba(0, 128, 0, 0.5) !important;
    color:#fff !important;
}

.dd_payment_method_color_blue, 
.dd_payment_method_color_blue option, 
.dd_payment_method_color_blue:focus
{
    background-color: rgba(0, 119, 255, 0.5) !important;
    color:#fff !important;
}

.dd_payment_method_color_purple, 
.dd_payment_method_color_purple option, 
.dd_payment_method_color_purple:focus
{
    background-color: rgba(128, 0, 128, 0.5) !important;
    color:#fff !important;
}
</style>