<script setup>
// import TheWelcome from '../components/TheWelcome.vue'
import AddUserFormVue from '@/components/FormUser.vue';
import AddPointOfSaleFormVue from '@/components/AddPointOfSaleFormVue.vue';
import ListUsersVue from '@/components/ListUsersVue.vue';
import ListPointOfSaleVue from '@/components/ListPointOfSaleVue.vue';
import SettingsReceiptMessage from '@/components/SettingsReceiptMessage.vue';
import SettingsHomePageMessage from '@/components/SettingsHomePageMessage.vue';
import SettingsInterfaceMessage from '@/components/SettingsInterfaceMessage.vue';
import KeypadValuesVue from '../components/Settings/KeypadValues.vue';
import BarCodeScanner_ConfigureReceipt from '../components/BarCodeScanner_ConfigureReceipt.vue'

import { onMounted, ref,unref, watch } from 'vue'
import { Field, Form, ErrorMessage,configure } from 'vee-validate';
import axios from 'axios';
import * as yup from 'yup';
import UIkit from 'uikit';

const form = ref();
const form2 = ref();

const schema = yup.object({
    "old_password":yup.string().required(),
    "new_password":yup.string().required(),
    "new_password2":yup.string().oneOf([yup.ref('new_password'), null], 'Passwords must match').required(),
});

const schema2 = yup.object({
    "number_minimum":yup.number().required(),
    "number_maximum":yup.number().required()
});

const defaultValues = {
    "old_password":"",
    "new_password":"",
    "new_password2":""
}

const defaultValues2 = {
    "number_minimum":"",
    "number_minimum":""
}
onMounted(()=>{
    form.value.resetForm({values:defaultValues})
})
const onSubmit = async (values)=>{
    console.log('Submitting form',values)
    try{
        await axios.post('/updatePassword',values);
        UIkit.notification("<span uk-icon='icon: check'></span> Mot de passe modifié avec succès",{status:'success',pos:'bottom-right'});
        form.value.resetForm({values:defaultValues})
    }
    catch(err){
        console.error(err);
        UIkit.notification("<span uk-icon='icon: close'></span> Erreure lors de la modification du mot de passe",{status:'danger',pos:'bottom-right'});
    }
}

onMounted(async ()=>{
    try{
        let res = await axios.get('/api/v1/settings/numberRange')
        console.log('RES:',res)
        form2.value.resetForm({values:{
            number_minimum:res.data.value.min,
            number_maximum:res.data.value.max,
            enabled:res.data.value.enabled,
        }});
    }
    catch(err){
        console.error(err);
    }
});

const onSubmit2 = async (values)=>{
    console.log(values)
    let data = {
        key:'numberRange',
        value:{
            min:Number(values.number_minimum),
            max:Number(values.number_maximum),
            enabled:values.enabled
        }
    }
    try{
        let res = await axios.post('/api/v1/settings/',data);
        UIkit.notification("<span uk-icon='icon: check'></span> Mot de passe modifié avec succès",{status:'success',pos:'bottom-right'});
        form2.value.resetForm({values:{
            number_minimum:res.data.value.min,
            number_maximum:res.data.value.max,
            enabled:res.data.value.enabled,
        }});
    }
    catch(err){
        console.error(err);
        UIkit.notification("<span uk-icon='icon: close'></span> Erreure lors de la modification du mot de passe",{status:'danger',pos:'bottom-right'});
    }
}
</script>

<template lang="pug">
div.uk-width-1-1
    ul.uk-breadcrumb 
        li
            span Réglages 
        li
            span Général
div(uk-grid="masonry: true")
    div(class="uk-width-1-1@s uk-width-1-2@m")
        div.uk-card.uk-card-default.uk-card-small.uk-card-body
            h3 Valeurs affichées dans le clavier
            KeypadValuesVue

    div(class="uk-width-1-1@s uk-width-1-2@m")
        div.uk-card.uk-card-default.uk-card-small.uk-card-body
            h3 Changement de votre mot de passe
            div
                Form(ref="form" @submit="onSubmit" :validation-schema="schema" v-slot="{ resetForm, meta, validate}")
                    Field(name="old_password" v-slot="{ field, errorMessage }")
                        div.uk-margin.uk-width-1-1
                            label.uk-form-label(for="form-keypad-values") Mot de passe actuel
                            div.uk-form-controls
                                input.uk-input(type="password" placeholder="" v-bind="field")
                    Field(name="new_password" v-slot="{ field, errorMessage }")
                        div.uk-margin.uk-width-1-1
                            label.uk-form-label(for="form-keypad-values") Nouveau mot de passe
                            div.uk-form-controls
                                input.uk-input(type="password" placeholder="" v-bind="field")
                    Field(name="new_password2" v-slot="{ field, errorMessage }")
                        div.uk-margin.uk-width-1-1
                            label.uk-form-label(for="form-keypad-values") Ré-entrer le nouveau mot de passe
                            div.uk-form-controls
                                input.uk-input(type="password" placeholder="" v-bind="field")
                    button.uk-button.uk-button-primary.uk-margin-right(type="submit" :disabled="!meta.valid || !meta.dirty") Enregistrer
                    button.uk-button.uk-button-link(type="button" @click="resetForm" :disabled="!meta.dirty") Annuler
   
    div(class="uk-width-1-1@s uk-width-1-2@m")
        div.uk-card.uk-card-default.uk-card-small.uk-card-body
            h3 Numéro de membre
            p.uk-text-meta Numéros réservé par le système pour les numéro générés automatiquement lors de la création de nouveau membres.
            
            Form(ref="form2" @submit="onSubmit2" :validation-schema="schema2" v-slot="{ resetForm, meta, validate}")
                div.uk-width-4-5.uk-flex.uk-flex-bottom(v-if="mode!='update'")
                    div.uk-flex.uk-flex-column
                        div
                            Field.uk-checkbox(name="enabled" type="checkbox" :value="true" :unchecked-value="false" v-slot="field")
                            | &nbsp;Activer
                        div.uk-text-muted.uk-text-small Enlever la possibilité aux utilisateur d'utiliser la numérotation automatique.
                Field(name="number_minimum" v-slot="{ field, errorMessage }")
                    div.uk-margin.uk-width-1-1
                        label.uk-form-label Minimum
                        div.uk-form-controls
                            input.uk-input(type="text" placeholder="" v-bind="field")
                Field(name="number_maximum" v-slot="{ field, errorMessage }")
                    div.uk-margin.uk-width-1-1
                        label.uk-form-label Maximum
                        div.uk-form-controls
                            input.uk-input(type="text" placeholder="" v-bind="field")
               
                button.uk-button.uk-button-primary.uk-margin-right(type="submit" :disabled="!meta.valid || !meta.dirty") Enregistrer
                button.uk-button.uk-button-link(type="button" @click="resetForm" :disabled="!meta.dirty") Annuler
    
    div(class="uk-width-1-1@s uk-width-1-2@m")
        div.uk-card.uk-card-default.uk-card-small.uk-card-body
            h3 Configuration du lecteur de code à barres
            p.uk-text-meta Scanner les code QR en ordre pour configurer le lecteur correctement. Sans cette configuration, le lecteur ne fonctionnera pas automatiquement sur la page INFO du Membre.
            BarCodeScanner_ConfigureReceipt
            
</template>