<script setup>
import { onMounted, ref,unref, watch} from 'vue'
import axios from 'axios';
import { Field, Form, ErrorMessage,configure } from 'vee-validate';
import * as yup from 'yup';
import { schema_add_user } from '@/../../validation_schemas/users.mjs';
import UIkit from 'uikit';

const defaultValues = {
    "username":"",
    "password":"",
    "display_name":"",
    "firstname":"",
    "lastname":"",
    "city":"",
    "email":"",
    "group_type":"disabled",
    "last_access":"",
    "allowed_pos":""
}

const form_error = ref("");

const onSubmit = async (values,{resetForm})=>{
    try{
        const res = await axios.post(`/api/v1/users/`,values);
        resetForm();
        form_error.value = '';
        UIkit.notification("<span uk-icon='icon: check'></span> Utilisateur ajouté avec succès",{status:'success',pos:'bottom-right'});
    }
    catch(err){
        console.error(err);
        console.error(err.code);
        console.error(err.message);
        form_error.value = err.message;
        UIkit.notification("<span uk-icon='icon: close'></span> Erreure lors de l'ajout du nouvel utilisateur",{status:'danger',pos:'bottom-right'});
    }
}

onMounted(async ()=>{
    try{
        const res = await axios.get(`/api/v1/pointofsales/`);
        posList.value = res.data;

        console.log('posList',posList.value)
    }
    catch(err){
        console.error(err);
    }
})

const posList = ref([]);
const currentGroup = ref('');
</script>


<template lang="pug">
Form(@submit="onSubmit" :validation-schema="schema_add_user" v-slot="{ resetForm, meta, validate}" :initial-values="defaultValues")
    
    Field(name="username" v-slot="{ field, errorMessage }")
        div.uk-margin.uk-width-2-5
            label.uk-form-label Nom d'utilisateur *
            div.uk-form-controls
                input.uk-input(type="text" placeholder="" :class="{'uk-form-danger': errorMessage}" v-bind="field")
                
    
    Field(name="password" v-slot="{ field, errorMessage }")
        div.uk-margin.uk-width-2-5
            label.uk-form-label Mot de passe *
            div.uk-form-controls
                input.uk-input(type="password" placeholder="" :class="{'uk-form-danger': errorMessage}" v-bind="field")
    
    Field(name="display_name" v-slot="{ field, errorMessage }")
        div.uk-margin.uk-width-2-5
            label.uk-form-label Nom affiché *
            div.uk-form-controls
                input.uk-input(type="text" placeholder="" :class="{'uk-form-danger': errorMessage}" v-bind="field")
   
    Field(name="group_type" v-slot="{ field, errorMessage }" @input="currentGroup = $event.target.value")
        div.uk-margin.uk-width-2-5
            label.uk-form-label Groupe
            div.uk-form-controls
                select.uk-select(type="text" placeholder="" :class="{'uk-form-danger': errorMessage}" v-bind="field")
                    option(value="disabled") Inactif
                    option(value="user") Utilisateur
                    option(value="posuser") Utilisateur d'un point de vente
                    option(value="admin") Administrateur
    
    Field(name="allowed_pos" v-slot="{ field, errorMessage }"  v-if="currentGroup == 'posuser'")
        div.uk-margin.uk-width-2-5
            label.uk-form-label Point de vente
            div.uk-form-controls
                select.uk-select(type="text" placeholder="" :class="{'uk-form-danger': errorMessage}" v-bind="field")
                    template(v-for="pos in posList")
                        option(:value="pos._id") {{pos.pos_name}}
    
    Field(name="firstname" v-slot="{ field, errorMessage }") 
        div.uk-margin.uk-width-2-5
            label.uk-form-label Prénom
            div.uk-form-controls
                input.uk-input(type="text" placeholder="" :class="{'uk-form-danger': errorMessage}" v-bind="field")    
    
    Field(name="lastname" v-slot="{ field, errorMessage }")
        div.uk-margin.uk-width-2-5
            label.uk-form-label Nom
            div.uk-form-controls
                input.uk-input(type="text" placeholder="" :class="{'uk-form-danger': errorMessage}" v-bind="field")    
    
    Field(name="city" v-slot="{ field, errorMessage }")
        div.uk-margin.uk-width-2-5
            label.uk-form-label Ville
            div.uk-form-controls
                input.uk-input(type="text" placeholder="" :class="{'uk-form-danger': errorMessage}" v-bind="field")   
    
    Field(name="email" v-slot="{ field, errorMessage }")
        div.uk-margin.uk-width-2-5
            label.uk-form-label Courriel
            div.uk-form-controls
                input.uk-input(type="text" placeholder="" :class="{'uk-form-danger': errorMessage}" v-bind="field")  
    
    p(uk-margin)
        button.uk-button.uk-button-primary.uk-margin-right(type="submit" :disabled="!meta.valid")
            | Ajouter
        button.uk-button.uk-button-link(type="button" @click="resetForm" :disabled="!meta.dirty")
            | Annuler
        div.uk-alert-danger(uk-alert v-if="form_error.length")
            a.uk-alert-close(uk-close)
            | {{ form_error }}
</template>